import './Header.css';

function HeaderLinkExternal(props) {
    return (
        <div className="Header-item">
            <a href={props.href} className="Header-link" target={props.target}>{props.text}</a>
        </div>
    );
}

export default HeaderLinkExternal;
