import '../index.css';
import axios from 'axios';
import { useState, useEffect } from 'react'
import ParagraphWithHeader from "../common/ParagraphWithHeader";

function Home() {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('')

    useEffect(() => {
        axios.get('https://cms.kashifshah.net/home').then(response => {
            setTitle(response.data.Title);
            setBody(response.data.Body);
        })

        return () => {

        }
    }, [])

    return (
        <div className="Index-container">
            <ParagraphWithHeader
                header={title}
                content={body} />
        </div>
    );
}

export default Home;
