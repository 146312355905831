import './Header.css';

import HeaderLinkExternal from "./HeaderLinkExternal";
import HeaderLinkInternal from "./HeaderLinkInternal";

function Header() {
    return (
        <header className="Header">
            <div className="Header-item Header-logo">
                <a href="/" className="Header-link">kashifshah.net</a>
            </div>
            <HeaderLinkInternal className="Header-link" to="/art" text="Art"/>
            <HeaderLinkExternal href="https://read.cv/kashif" text="CV" target="_blank"/>
            <HeaderLinkInternal className="Header-link" to="/driving" text="Driving"/>
            <HeaderLinkInternal className="Header-link" to="/knowledge" text="Knowledge"/>
            <HeaderLinkInternal className="Header-link" to="/writing" text="Writing"/>
        </header>
    );
}

export default Header;
