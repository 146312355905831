import './Art.css';
import { useState, useEffect } from 'react';
import axios from 'axios'
import ArtItem from './ArtItem'

function Art() {
    const [arts, setArts] = useState([])

    useEffect(() => {
        axios.get('https://cms.kashifshah.net/arts').then(response => {
            console.log({ arts: response.data });

            setArts(response.data);
        })

        return () => { }
    }, [])


    return (
        <div className={{ Art }}> {
            arts.map(item => {
                return <ArtItem id={item.id}
                />
            })
        } </div>
    );
}

export default Art;