import '../index.css';

function ParagraphWithoutHeader(props) {
    return (
        <div className="Paragraph-container">
            <p className="Paragraph-content">{props.content}</p>
        </div>
    );
}

export default ParagraphWithoutHeader;
