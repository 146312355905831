import './Art.css';
import { useState, useEffect } from 'react';
import axios from 'axios'
import ArtCollection from './ArtCollection';

function ArtItem(props) {
    const [art, setArt] = useState({})

    console.log({ lookupArtItem: props.id });

    useEffect(() => {
        axios.get(`https://cms.kashifshah.net/arts/${props.id}`).then(response => {
            console.log({ artItem: response.data })

            setArt(response.data);
        })

        return () => { }
    }, [props.id])


    return (
        <div>
            <p className="Art-header">{art.Title}</p>
            <p className="Art-content">{art.Body}</p>

            {art?.art_collections?.map(collection => {
                return (<ArtCollection id={collection.id} />)
            })}
        </div>
    );
}

export default ArtItem;
