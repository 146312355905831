import '../index.css';
import ParagraphWithHeader from "../common/ParagraphWithHeader";
import LinkExternal from "../common/LinkExternal";

function Writing() {
    return (
        <div className="Index-container">
            <ParagraphWithHeader
                header="I think, therefore I am"
                content="Welcome to my writer's corner."/>
            <LinkExternal href="/writing/artificial-consciousness" text="Artificial Consciousness"/>
            <LinkExternal href={"/writing/augustine-confessions"} text={"Questions about Augustine's Confessions"}/>
        </div>
    );
}

export default Writing;
