import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './common/header/Header'
import Art from './art/Art'
import Driving from './driving/Driving'
import Knowledge from './knowledge/Knowledge'
import Writing from './writing/Writing'
import ArtificialConsciousness from "./writing/ArtificialConsciousness";
import AugustineConfessions from "./writing/AugustineConfessions";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <aside className='Index-aside'>
        <Header />
      </aside>
      <main className="Index-main">
        <Routes>
          <Route exact path='/' element={<App />} />
          <Route exact path="/art" element={<Art />} />
          <Route exact path="/driving" element={<Driving />} />
          <Route exact path="/knowledge" element={<Knowledge />} />
          <Route exact path="/writing" element={<Writing />} />
          <Route exact path="/writing/artificial-consciousness" element={<ArtificialConsciousness />} />
          <Route exact path="/writing/augustine-confessions" element={<AugustineConfessions />} />
        </Routes>
      </main>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
