import './Header.css';
import {Link} from "react-router-dom";

function HeaderLinkInternal(props) {
    return (
        <div className="Header-item">
            <Link className="Header-link" to={props.to}>{props.text}</Link>
        </div>
    );
}

export default HeaderLinkInternal;