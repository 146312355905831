import './Art.css';
import { useState, useEffect } from 'react';
import axios from 'axios'

function ArtImage(props) {
    const [image, setImage] = useState({})

    useEffect(() => {
        axios.get(`https://cms.kashifshah.net/art-images/${props.id}`).then(response => {
            console.log({ lookupArtImage: response.data });

            setImage(response.data);
        })

        return () => { }
    }, [props.id])


    return (!image.Image ? null :
        <div className='ArtImage'>
            <p className='ArtImage-header'>{image.Title}</p>
            <img src={`${image.Image?.formats.small.url}`} alt={image.ImageAlt} />
        </div>
    );
}

export default ArtImage;
