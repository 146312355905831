import '../index.css';

function ParagraphWithHeader(props) {
    return (
        <div className="Index-container">
            <p className="Paragraph-header">{props.header}</p>
            <p className="Paragraph-content">{props.content}</p>
        </div>
    );
}

export default ParagraphWithHeader;
