import '../index.css';
import ParagraphWithHeader from "../common/ParagraphWithHeader";
import LinkExternal from "../common/LinkExternal";

function Knowledge() {
    return (
        <div className="Index-container">
            <ParagraphWithHeader
                header="To boldly go where no one has gone before"
                content="Welcome to my knowledge worker's corner." />
            <ParagraphWithHeader
                header="0. Random Knowledge"
                content="For now here is a random collection of bookmarks.  Given time, this area will be re-structured to be more usable as a resource." />
            <LinkExternal href="https://en.wikipedia.org/wiki/Turritopsis_nutricula"
                text="https://en.wikipedia.org/wiki/Turritopsis_nutricula" />
            <LinkExternal
                href="https://arstechnica.com/science/2021/01/what-psychology-of-mass-mobilization-can-tell-us-about-the-capitol-riot/"
                text="https://arstechnica.com/science/2021/01/what-psychology-of-mass-mobilization-can-tell-us-about-the-capitol-riot/" />
            <LinkExternal
                href="http://shape-of-code.coding-guidelines.com/2021/01/17/software-effort-estimation-is-mostly-fake-research/"
                text="http://shape-of-code.coding-guidelines.com/2021/01/17/software-effort-estimation-is-mostly-fake-research/" />
            <LinkExternal href="http://m.nautil.us/blog/can-you-treat-loneliness-by-creating-an-imaginary-friend"
                text="http://m.nautil.us/blog/can-you-treat-loneliness-by-creating-an-imaginary-friend" />
            <LinkExternal href="https://nypost.com/2020/01/24/how-to-avoid-americas-coming-secession-crisis/"
                text="https://nypost.com/2020/01/24/how-to-avoid-americas-coming-secession-crisis/" />
            <LinkExternal
                href="https://townhall.com/columnists/kurtschlichter/2020/12/14/could-secession-succeed-n2581466"
                text="https://townhall.com/columnists/kurtschlichter/2020/12/14/could-secession-succeed-n2581466" />
            <LinkExternal href="https://www.tabletmag.com/sections/news/articles/everything-is-broken"
                text="https://www.tabletmag.com/sections/news/articles/everything-is-broken" />
            <LinkExternal
                href="https://www.salon.com/2021/01/08/pro-trump-protesters-who-was-crushed-to-death-in-capitol-riot-carried-dont-tread-on-me-flag-_partner/"
                text="https://www.salon.com/2021/01/08/pro-trump-protesters-who-was-crushed-to-death-in-capitol-riot-carried-dont-tread-on-me-flag-_partner/" />
            <LinkExternal href="https://data.ibtimes.sg/en/full/45978/michael-lindell.png"
                text="https://data.ibtimes.sg/en/full/45978/michael-lindell.png" />
            <LinkExternal href="https://www.youtube.com/watch?v=6wm-NtMkqz8"
                text="https://www.youtube.com/watch?v=6wm-NtMkqz8" />
            <LinkExternal
                href="https://news.google.com/articles/CAIiEF6bnlXTnyWjw4jJqZSoZyoqGQgEKhAIACoHCAowjsP7CjCSpPQCMM_b5QU?hl=en-US&gl=US&ceid=US%3Aen"
                text="https://news.google.com/articles/CAIiEF6bnlXTnyWjw4jJqZSoZyoqGQgEKhAIACoHCAowjsP7CjCSpPQCMM_b5QU?hl=en-US&gl=US&ceid=US%3Aen" />
            <LinkExternal
                href="https://www.washingtonpost.com/politics/pence-rioters-capitol-attack/2021/01/15/ab62e434-567c-11eb-a08b-f1381ef3d207_story.html?outputType=amp"
                text="https://www.washingtonpost.com/politics/pence-rioters-capitol-attack/2021/01/15/ab62e434-567c-11eb-a08b-f1381ef3d207_story.html?outputType=amp" />
            <LinkExternal
                href="https://www.washingtonpost.com/politics/pence-rioters-capitol-attack/2021/01/15/ab62e434-567c-11eb-a08b-f1381ef3d207_story.html?outputType=amp"
                text="https://www.washingtonpost.com/politics/pence-rioters-capitol-attack/2021/01/15/ab62e434-567c-11eb-a08b-f1381ef3d207_story.html?outputType=amp" />
            <LinkExternal href="https://www.devlead.se/posts/2021/2021-01-15-my-preferred-console-stack"
                text="https://www.devlead.se/posts/2021/2021-01-15-my-preferred-console-stack" />
            <LinkExternal href="https://www.washingtonpost.com/dc-md-va/2021/01/14/dc-police-capitol-riot/?arc404=true"
                text="https://www.washingtonpost.com/dc-md-va/2021/01/14/dc-police-capitol-riot/?arc404=true" />
            <LinkExternal
                href="https://news.google.com/articles/CAIiEGIkwUk0uVBWAemaVJDqEQ0qGAgEKg8IACoHCAowjtSUCjC30XQwzqe5AQ?hl=en-US&gl=US&ceid=US%3Aen"
                text="https://news.google.com/articles/CAIiEGIkwUk0uVBWAemaVJDqEQ0qGAgEKg8IACoHCAowjtSUCjC30XQwzqe5AQ?hl=en-US&gl=US&ceid=US%3Aen" />
            <LinkExternal
                href="https://www.washingtonpost.com/national/wyoming-republicans-budget-shortfall/2021/01/10/b1f0f956-4c47-11eb-a9f4-0e668b9772ba_story.html"
                text="https://www.washingtonpost.com/national/wyoming-republicans-budget-shortfall/2021/01/10/b1f0f956-4c47-11eb-a9f4-0e668b9772ba_story.html" />
            <LinkExternal
                href="https://www.washingtonpost.com/politics/2021/01/14/demographic-divergence-that-helps-explain-perceptions-capitol-rioters/"
                text="https://www.washingtonpost.com/politics/2021/01/14/demographic-divergence-that-helps-explain-perceptions-capitol-rioters/" />
            <LinkExternal
                href="https://news.google.com/articles/CAIiEGIkwUk0uVBWAemaVJDqEQ0qGAgEKg8IACoHCAowjtSUCjC30XQwzqe5AQ?hl=en-US&gl=US&ceid=US%3Aen"
                text="https://news.google.com/articles/CAIiEGIkwUk0uVBWAemaVJDqEQ0qGAgEKg8IACoHCAowjtSUCjC30XQwzqe5AQ?hl=en-US&gl=US&ceid=US%3Aen" />
            <LinkExternal href="https://qz.com/1956272/what-qanon-shaman-jake-angelis-tattoos-mean/"
                text="https://qz.com/1956272/what-qanon-shaman-jake-angelis-tattoos-mean/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25786575"
                text="https://news.ycombinator.com/item?id=25786575" />
            <LinkExternal
                href="https://news.google.com/articles/CBMiWGh0dHBzOi8vYWJjMTEuY29tL3BvbGl0aWNzL2dvcC1sb3Nlcy1zZXZlcmFsLXRob3VzYW5kLWluLW5jLWFmdGVyLWNhcGl0b2wtcmlvdHMvOTY2NjMwOS_SAWFodHRwczovL2FiYzExLmNvbS9hbXAvcmVwdWJsaWNhbi1wYXJ0eS1kZW1vY3JhdGljLXN3aXRjaGluZy1wYXJ0aWVzLXBvbGl0aWNhbC1hZmZpbGlhdGlvbi85NjY2MzA5?hl=en-US&gl=US&ceid=US%3Aen"
                text="https://news.google.com/articles/CBMiWGh0dHBzOi8vYWJjMTEuY29tL3BvbGl0aWNzL2dvcC1sb3Nlcy1zZXZlcmFsLXRob3VzYW5kLWluLW5jLWFmdGVyLWNhcGl0b2wtcmlvdHMvOTY2NjMwOS_SAWFodHRwczovL2FiYzExLmNvbS9hbXAvcmVwdWJsaWNhbi1wYXJ0eS1kZW1vY3JhdGljLXN3aXRjaGluZy1wYXJ0aWVzLXBvbGl0aWNhbC1hZmZpbGlhdGlvbi85NjY2MzA5?hl=en-US&gl=US&ceid=US%3Aen" />
            <LinkExternal href="https://gumroad.com/d/6a153fd9d6a5ed0ad752ebb7ef8823a4"
                text="https://gumroad.com/d/6a153fd9d6a5ed0ad752ebb7ef8823a4" />
            <LinkExternal href="https://gumroad.com/l/callthehallsguide"
                text="https://gumroad.com/l/callthehallsguide" />
            <LinkExternal
                href="https://www.usatoday.com/story/college/2017/01/24/5-ways-to-contact-your-elected-officials-and-make-your-voice-heard/37427477/"
                text="https://www.usatoday.com/story/college/2017/01/24/5-ways-to-contact-your-elected-officials-and-make-your-voice-heard/37427477/" />
            <LinkExternal href="https://www.flsenate.gov/About/EffectiveCommunication"
                text="https://www.flsenate.gov/About/EffectiveCommunication" />
            <LinkExternal href="https://www.thoughtco.com/write-effective-letters-to-congress-3322301"
                text="https://www.thoughtco.com/write-effective-letters-to-congress-3322301" />
            <LinkExternal href="https://www.washingtonpost.com/dc-md-va/2021/01/14/dc-police-capitol-riot/?arc404=true"
                text="https://www.washingtonpost.com/dc-md-va/2021/01/14/dc-police-capitol-riot/?arc404=true" />
            <LinkExternal href="https://thedorkweb.substack.com/p/a-week-with-plan-9"
                text="https://thedorkweb.substack.com/p/a-week-with-plan-9" />
            <LinkExternal
                href="https://www.washingtonpost.com/politics/interactive/2021/annotated-trump-speech-jan-6-capitol/?itid=lb_complete-coverage-pro-trump-mob-storms-capitol-building_4"
                text="https://www.washingtonpost.com/politics/interactive/2021/annotated-trump-speech-jan-6-capitol/?itid=lb_complete-coverage-pro-trump-mob-storms-capitol-building_4" />
            <LinkExternal
                href="https://www.washingtonpost.com/politics/interactive/2021/annotated-trump-speech-jan-6-capitol/?itid=lb_complete-coverage-pro-trump-mob-storms-capitol-building_4"
                text="https://www.washingtonpost.com/politics/interactive/2021/annotated-trump-speech-jan-6-capitol/?itid=lb_complete-coverage-pro-trump-mob-storms-capitol-building_4" />
            <LinkExternal
                href="https://www.washingtonpost.com/politics/interactive/2021/annotated-trump-speech-jan-6-capitol/?itid=lb_complete-coverage-pro-trump-mob-storms-capitol-building_4"
                text="https://www.washingtonpost.com/politics/interactive/2021/annotated-trump-speech-jan-6-capitol/?itid=lb_complete-coverage-pro-trump-mob-storms-capitol-building_4" />
            <LinkExternal
                href="https://www.washingtonpost.com/politics/2019/03/14/trump-again-nods-toward-violence-by-his-supporters-maybe-something-bigger/"
                text="https://www.washingtonpost.com/politics/2019/03/14/trump-again-nods-toward-violence-by-his-supporters-maybe-something-bigger/" />
            <LinkExternal href="https://www.politico.com/news/2021/01/12/mikie-sherrill-capitol-hill-attack-458655"
                text="https://www.politico.com/news/2021/01/12/mikie-sherrill-capitol-hill-attack-458655" />
            <LinkExternal href="https://www.politico.com/news/2021/01/12/mikie-sherrill-capitol-hill-attack-458655"
                text="https://www.politico.com/news/2021/01/12/mikie-sherrill-capitol-hill-attack-458655" />
            <LinkExternal
                href="https://news.google.com/articles/CAIiEBqhJCOgW1DUrrR3b9gU8Y0qFwgEKg8IACoHCAowjuuKAzCWrzwwt4QY?hl=en-US&gl=US&ceid=US%3Aen"
                text="https://news.google.com/articles/CAIiEBqhJCOgW1DUrrR3b9gU8Y0qFwgEKg8IACoHCAowjuuKAzCWrzwwt4QY?hl=en-US&gl=US&ceid=US%3Aen" />
            <LinkExternal
                href="https://news.google.com/articles/CAIiED_zlnOsUE2STMW0fxCbi7gqGQgEKhAIACoHCAowwL2ICzCckocDMMaPqQY?hl=en-US&gl=US&ceid=US%3Aen"
                text="https://news.google.com/articles/CAIiED_zlnOsUE2STMW0fxCbi7gqGQgEKhAIACoHCAowwL2ICzCckocDMMaPqQY?hl=en-US&gl=US&ceid=US%3Aen" />
            <LinkExternal
                href="https://news.google.com/articles/CAIiEDyeC3kCMm9reEpU-G-0TBkqGQgEKhAIACoHCAow77zbCjDiq8wBMNiCsgU?hl=en-US&gl=US&ceid=US%3Aen"
                text="https://news.google.com/articles/CAIiEDyeC3kCMm9reEpU-G-0TBkqGQgEKhAIACoHCAow77zbCjDiq8wBMNiCsgU?hl=en-US&gl=US&ceid=US%3Aen" />
            <LinkExternal href="https://a16z.com/2012/01/19/management-debt/"
                text="https://a16z.com/2012/01/19/management-debt/" />
            <LinkExternal
                href="https://michaelnaimark.medium.com/a-cheap-simple-hack-for-improving-your-online-classtime-experiences-802071cd34c1"
                text="https://michaelnaimark.medium.com/a-cheap-simple-hack-for-improving-your-online-classtime-experiences-802071cd34c1" />
            <LinkExternal
                href="https://stackoverflow.com/questions/288512/resizing-a-window-to-a-set-size-in-linux/59234259#59234259"
                text="https://stackoverflow.com/questions/288512/resizing-a-window-to-a-set-size-in-linux/59234259#59234259" />
            <LinkExternal href="https://www.bisend.com/blog/top-iis-rewrite-rules"
                text="https://www.bisend.com/blog/top-iis-rewrite-rules" />
            <LinkExternal href="https://inrupt.com/products/dev-tools" text="https://inrupt.com/products/dev-tools" />
            <LinkExternal href="https://github.com/microsoft/FASTER" text="https://github.com/microsoft/FASTER" />
            <LinkExternal
                href="https://www.reddit.com/r/linux/comments/kux9xx/success_iphone_7_with_dead_nand_netbooting/?utm_source=share&utm_medium=ios_app&utm_name=iossmf"
                text="https://www.reddit.com/r/linux/comments/kux9xx/success_iphone_7_with_dead_nand_netbooting/?utm_source=share&utm_medium=ios_app&utm_name=iossmf" />
            <LinkExternal href="https://lindeloev.github.io/tests-as-linear/"
                text="https://lindeloev.github.io/tests-as-linear/" />
            <LinkExternal
                href="https://www.nytimes.com/2019/03/28/smarter-living/productivity-isnt-about-time-management-its-about-attention-management.html"
                text="https://www.nytimes.com/2019/03/28/smarter-living/productivity-isnt-about-time-management-its-about-attention-management.html" />
            <LinkExternal
                href="https://stackoverflow.com/questions/29975684/applescript-to-swift-language-application-control"
                text="https://stackoverflow.com/questions/29975684/applescript-to-swift-language-application-control" />
            <LinkExternal
                href="https://medium.com/macoclock/everything-you-need-to-do-to-launch-an-applescript-from-appkit-on-macos-catalina-with-swift-1ba82537f7c3"
                text="https://medium.com/macoclock/everything-you-need-to-do-to-launch-an-applescript-from-appkit-on-macos-catalina-with-swift-1ba82537f7c3" />
            <LinkExternal href="https://joshspicer.com/applescript" text="https://joshspicer.com/applescript" />
            <LinkExternal href="https://docs.swift.org/swift-book/LanguageGuide/TheBasics.html"
                text="https://docs.swift.org/swift-book/LanguageGuide/TheBasics.html" />
            <LinkExternal
                href="https://www.sciencealert.com/we-could-learn-to-communicate-with-spiders-with-music-made-from-their-webs"
                text="https://www.sciencealert.com/we-could-learn-to-communicate-with-spiders-with-music-made-from-their-webs" />
            <LinkExternal href="https://github.com/libuv/libuv/issues/3050"
                text="https://github.com/libuv/libuv/issues/3050" />
            <LinkExternal href="https://psychology.providence.edu/faculty-members/christopher-bloom/"
                text="https://psychology.providence.edu/faculty-members/christopher-bloom/" />
            <LinkExternal href="https://kristiandupont.medium.com/m1-dev-setup-with-a-virtual-linux-box-1a2688231667"
                text="https://kristiandupont.medium.com/m1-dev-setup-with-a-virtual-linux-box-1a2688231667" />
            <LinkExternal href="https://cactusway.com/cactus-care-11-essential-tips-for-beginners/"
                text="https://cactusway.com/cactus-care-11-essential-tips-for-beginners/" />
            <LinkExternal href="https://www.dhs.wisconsin.gov/forms/f1/f10112.pdf"
                text="https://www.dhs.wisconsin.gov/forms/f1/f10112.pdf" />
            <LinkExternal href="https://onlinelibrary.wiley.com/doi/full/10.1002/ijop.12303"
                text="https://onlinelibrary.wiley.com/doi/full/10.1002/ijop.12303" />
            <LinkExternal href="https://www.marquette.edu/psychology/frl-inquiries-in-affective-science-lab.php"
                text="https://www.marquette.edu/psychology/frl-inquiries-in-affective-science-lab.php" />
            <LinkExternal href="https://www.brown.edu/news/2021-03-31/braingate-wireless"
                text="https://www.brown.edu/news/2021-03-31/braingate-wireless" />
            <LinkExternal href="https://sudo.pagerduty.com/for_engineers/"
                text="https://sudo.pagerduty.com/for_engineers/" />
            <LinkExternal href="https://www.sleepdiplomat.com/professor"
                text="https://www.sleepdiplomat.com/professor" />
            <LinkExternal href="https://neuroscience.berkeley.edu/research/"
                text="https://neuroscience.berkeley.edu/research/" />
            <LinkExternal
                href="https://podcasts.apple.com/us/podcast/the-joe-rogan-experience/id360084272?i=1000409857303"
                text="https://podcasts.apple.com/us/podcast/the-joe-rogan-experience/id360084272?i=1000409857303" />
            <LinkExternal
                href="https://www.sciencealert.com/someone-in-the-kalahari-collected-crystals-a-whopping-100-000-years-ago"
                text="https://www.sciencealert.com/someone-in-the-kalahari-collected-crystals-a-whopping-100-000-years-ago" />
            <LinkExternal href="https://hub.docker.com/_/microsoft-dotnet-sdk"
                text="https://hub.docker.com/_/microsoft-dotnet-sdk" />
            <LinkExternal href="https://anonymousplanet.org/" text="https://anonymousplanet.org/" />
            <LinkExternal
                href="https://therecord.media/google-collects-20-times-more-telemetry-from-android-devices-than-apple-from-ios/"
                text="https://therecord.media/google-collects-20-times-more-telemetry-from-android-devices-than-apple-from-ios/" />
            <LinkExternal
                href="https://www.npr.org/2021/03/25/980944495/sleeping-octopuses-may-have-dreams-but-theyre-probably-brief"
                text="https://www.npr.org/2021/03/25/980944495/sleeping-octopuses-may-have-dreams-but-theyre-probably-brief" />
            <LinkExternal href="https://runpondr.com/blog/brainstorming"
                text="https://runpondr.com/blog/brainstorming" />
            <LinkExternal
                href="https://www.openculture.com/2021/02/the-open-syllabus-project-visualizes-the-1000000-books-frequently-assigned-in-college-courses.html"
                text="https://www.openculture.com/2021/02/the-open-syllabus-project-visualizes-the-1000000-books-frequently-assigned-in-college-courses.html" />
            <LinkExternal href="https://wattenberger.com/blog/react-and-d3"
                text="https://wattenberger.com/blog/react-and-d3" />
            <LinkExternal href="http://ifconfig.co/" text="http://ifconfig.co/" />
            <LinkExternal href="https://arxiv.org/abs/2103.00352" text="https://arxiv.org/abs/2103.00352" />
            <LinkExternal
                href="https://bradfrost.com/blog/post/front-of-the-front-end-and-back-of-the-front-end-web-development/"
                text="https://bradfrost.com/blog/post/front-of-the-front-end-and-back-of-the-front-end-web-development/" />
            <LinkExternal href="https://bradfrost.com/blog/post/atomic-web-design/"
                text="https://bradfrost.com/blog/post/atomic-web-design/" />
            <LinkExternal
                href="https://bradfrost.com/blog/post/the-technical-side-of-design-systems-at-css-day-in-amsterdam/"
                text="https://bradfrost.com/blog/post/the-technical-side-of-design-systems-at-css-day-in-amsterdam/" />
            <LinkExternal href="https://julian.digital/2020/04/19/airpods-as-a-platform/"
                text="https://julian.digital/2020/04/19/airpods-as-a-platform/" />
            <LinkExternal
                href="https://finance.yahoo.com/news/bill-gates-texas-gov-greg-abbott-power-outage-claims-climate-change-002303596.html"
                text="https://finance.yahoo.com/news/bill-gates-texas-gov-greg-abbott-power-outage-claims-climate-change-002303596.html" />
            <LinkExternal href="https://www.norberhuis.nl/how-i-started-believing-in-cycle-time-over-estimation/"
                text="https://www.norberhuis.nl/how-i-started-believing-in-cycle-time-over-estimation/" />
            <LinkExternal href="https://blog.royalsloth.eu/posts/on-navigating-a-large-codebase/"
                text="https://blog.royalsloth.eu/posts/on-navigating-a-large-codebase/" />
            <LinkExternal href="https://meltano.com/" text="https://meltano.com/" />
            <LinkExternal href="https://github.com/McYoloSwagHam/win3wm"
                text="https://github.com/McYoloSwagHam/win3wm" />
            <LinkExternal
                href="https://petewarden.com/2021/02/28/how-screen-scraping-and-tinyml-can-turn-any-dial-into-an-api/"
                text="https://petewarden.com/2021/02/28/how-screen-scraping-and-tinyml-can-turn-any-dial-into-an-api/" />
            <LinkExternal href="https://flowchart.fun/" text="https://flowchart.fun/" />
            <LinkExternal
                href="http://muratbuffalo.blogspot.com/2021/02/foundational-distributed-systems-papers.html?m=1"
                text="http://muratbuffalo.blogspot.com/2021/02/foundational-distributed-systems-papers.html?m=1" />
            <LinkExternal href="https://ccbjournal.com/articles/recognizing-vendor-fraud"
                text="https://ccbjournal.com/articles/recognizing-vendor-fraud" />
            <LinkExternal href="https://hireautism.org/" text="https://hireautism.org/" />
            <LinkExternal href="https://acilearning.com/blog" text="https://acilearning.com/blog" />
            <LinkExternal
                href="https://www.fullstacklabs.co/blog/software-development-price-guide-hourly-rate-comparison"
                text="https://www.fullstacklabs.co/blog/software-development-price-guide-hourly-rate-comparison" />
            <LinkExternal href="https://www.wicourts.gov/sc/scrule/DisplayDocument.pdf?content=pdf&seqNo=320589#page60"
                text="https://www.wicourts.gov/sc/scrule/DisplayDocument.pdf?content=pdf&seqNo=320589#page60" />
            <LinkExternal href="https://www.mentalhelp.net/anger/physiology/"
                text="https://www.mentalhelp.net/anger/physiology/" />
            <LinkExternal
                href="https://erikbern.com/2019/04/15/why-software-projects-take-longer-than-you-think-a-statistical-model.html#"
                text="https://erikbern.com/2019/04/15/why-software-projects-take-longer-than-you-think-a-statistical-model.html#" />
            <LinkExternal href="https://www.fbi.gov/contact-us/field-offices/milwaukee"
                text="https://www.fbi.gov/contact-us/field-offices/milwaukee" />
            <LinkExternal href="https://www.utl.is/index.php/en/rights-and-services-for-asylum-seekers"
                text="https://www.utl.is/index.php/en/rights-and-services-for-asylum-seekers" />
            <LinkExternal href="https://askjan.org/publications/consultants-corner/vol12iss05.cfm"
                text="https://askjan.org/publications/consultants-corner/vol12iss05.cfm" />
            <LinkExternal href="https://www.previant.com/workers-compensation-faq.html"
                text="https://www.previant.com/workers-compensation-faq.html" />
            <LinkExternal href="https://www.warshafsky.com/PIFirmWisconsin/WILawsWorkersCompensation"
                text="https://www.warshafsky.com/PIFirmWisconsin/WILawsWorkersCompensation" />
            <LinkExternal href="https://dwd.wisconsin.gov/laborlaw/" text="https://dwd.wisconsin.gov/laborlaw/" />
            <LinkExternal href="https://collections.louvre.fr/en/" text="https://collections.louvre.fr/en/" />
            <LinkExternal
                href="https://sportebois.medium.com/better-architecture-diagrams-for-agile-teams-actionable-tips-and-lessons-e76627dc4315"
                text="https://sportebois.medium.com/better-architecture-diagrams-for-agile-teams-actionable-tips-and-lessons-e76627dc4315" />
            <LinkExternal href="https://www.nature.com/articles/d41586-021-00782-w"
                text="https://www.nature.com/articles/d41586-021-00782-w" />
            <LinkExternal
                href="https://www.gruber-law.com/privacy-policy/?_ga=2.67783576.848943651.1615924037-2098462552.1615924037"
                text="https://www.gruber-law.com/privacy-policy/?_ga=2.67783576.848943651.1615924037-2098462552.1615924037" />
            <LinkExternal
                href="https://www.fullstacklabs.co/blog/software-development-price-guide-hourly-rate-comparison"
                text="https://www.fullstacklabs.co/blog/software-development-price-guide-hourly-rate-comparison" />
            <LinkExternal href="https://www.drupal.org/docs/drupal-apis/entity-api/bundles"
                text="https://www.drupal.org/docs/drupal-apis/entity-api/bundles" />
            <LinkExternal href="https://www.cs.yale.edu/homes/aspnes/classes/223/notes.html"
                text="https://www.cs.yale.edu/homes/aspnes/classes/223/notes.html" />
            <LinkExternal href="https://dynomight.net/better-DIY-air-purifier.html"
                text="https://dynomight.net/better-DIY-air-purifier.html" />
            <LinkExternal href="https://dynomight.net/better-DIY-air-purifier.html"
                text="https://dynomight.net/better-DIY-air-purifier.html" />
            <LinkExternal
                href="https://www.inputmag.com/tech/deepfake-videos-of-tom-cruise-show-just-fast-its-improving"
                text="https://www.inputmag.com/tech/deepfake-videos-of-tom-cruise-show-just-fast-its-improving" />
            <LinkExternal
                href="https://petewarden.com/2021/02/28/how-screen-scraping-and-tinyml-can-turn-any-dial-into-an-api/"
                text="https://petewarden.com/2021/02/28/how-screen-scraping-and-tinyml-can-turn-any-dial-into-an-api/" />
            <LinkExternal
                href="https://www.inc.com/jason-aten/facebook-just-admitted-it-has-lost-its-battle-with-apple-over-privacy.html"
                text="https://www.inc.com/jason-aten/facebook-just-admitted-it-has-lost-its-battle-with-apple-over-privacy.html" />
            <LinkExternal href="https://www.norberhuis.nl/how-i-started-believing-in-cycle-time-over-estimation/"
                text="https://www.norberhuis.nl/how-i-started-believing-in-cycle-time-over-estimation/" />
            <LinkExternal href="https://www.deprocrastination.co/blog/how-to-be-productive-without-forcing-yourself"
                text="https://www.deprocrastination.co/blog/how-to-be-productive-without-forcing-yourself" />
            <LinkExternal href="https://www.norberhuis.nl/how-i-started-believing-in-cycle-time-over-estimation/"
                text="https://www.norberhuis.nl/how-i-started-believing-in-cycle-time-over-estimation/" />
            <LinkExternal
                href="https://github.com/hassio-addons/addon-influxdb/blob/master/influxdb/DOCS.md#integrating-into-home-assistant"
                text="https://github.com/hassio-addons/addon-influxdb/blob/master/influxdb/DOCS.md#integrating-into-home-assistant" />
            <LinkExternal href="https://github.com/hassio-addons/addon-grafana/blob/master/grafana/DOCS.md"
                text="https://github.com/hassio-addons/addon-grafana/blob/master/grafana/DOCS.md" />
            <LinkExternal href="https://gitlab.com/altepizza/nextbookmark"
                text="https://gitlab.com/altepizza/nextbookmark" />
            <LinkExternal href="https://www.home-assistant.io/more-info/unsupported/docker_configuration"
                text="https://www.home-assistant.io/more-info/unsupported/docker_configuration" />
            <LinkExternal href="https://carlschwan.eu/2020/12/29/adding-comments-to-your-static-blog-with-mastodon/"
                text="https://carlschwan.eu/2020/12/29/adding-comments-to-your-static-blog-with-mastodon/" />
            <LinkExternal href="https://www.hanselman.com/blog/how-do-you-use-systemdrawing-in-net-core"
                text="https://www.hanselman.com/blog/how-do-you-use-systemdrawing-in-net-core" />
            <LinkExternal href="https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1005268"
                text="https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1005268" />
            <LinkExternal href="https://www.linkace.org/" text="https://www.linkace.org/" />
            <LinkExternal href="https://danluu.com/essential-complexity/"
                text="https://danluu.com/essential-complexity/" />
            <LinkExternal
                href="https://www.ign.com/wikis/cyberpunk-2077/Best_Ripperdoc_Upgrades#Best_Circulatory_System_Upgrades"
                text="https://www.ign.com/wikis/cyberpunk-2077/Best_Ripperdoc_Upgrades#Best_Circulatory_System_Upgrades" />
            <LinkExternal href="https://how.complexsystems.fail/" text="https://how.complexsystems.fail/" />
            <LinkExternal href="https://hardmath123.github.io/chaos-game-fractal-foliage.html"
                text="https://hardmath123.github.io/chaos-game-fractal-foliage.html" />
            <LinkExternal href="https://neurodiverse.team/" text="https://neurodiverse.team/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25559571"
                text="https://news.ycombinator.com/item?id=25559571" />
            <LinkExternal href="https://www.staycaffeinated.com/2020/12/27/building-my-own-homekit-thermostat-v1"
                text="https://www.staycaffeinated.com/2020/12/27/building-my-own-homekit-thermostat-v1" />
            <LinkExternal href="https://apenwarr.ca/log/20201227" text="https://apenwarr.ca/log/20201227" />
            <LinkExternal href="https://stackoverflow.com/questions/47781469/linq-select-property-by-name#47781545"
                text="https://stackoverflow.com/questions/47781469/linq-select-property-by-name#47781545" />
            <LinkExternal href="https://www.ign.com/wikis/cyberpunk-2077"
                text="https://www.ign.com/wikis/cyberpunk-2077" />
            <LinkExternal href="https://buzzword.engineering/post/blog-tech-stack"
                text="https://buzzword.engineering/post/blog-tech-stack" />
            <LinkExternal href="https://softwarefordays.com/post/resolving-the-fp-time-paradox/"
                text="https://softwarefordays.com/post/resolving-the-fp-time-paradox/" />
            <LinkExternal href="https://apenwarr.ca/log/20201227" text="https://apenwarr.ca/log/20201227" />
            <LinkExternal
                href="https://www.embedded-computing.com/home-page/guess-what-s-under-the-hood-of-the-subaru-levorg-adas-and-programmable-logic"
                text="https://www.embedded-computing.com/home-page/guess-what-s-under-the-hood-of-the-subaru-levorg-adas-and-programmable-logic" />
            <LinkExternal href="https://techcrunch.com/2020/12/27/ant-group-financial-clampdown/amp/"
                text="https://techcrunch.com/2020/12/27/ant-group-financial-clampdown/amp/" />
            <LinkExternal href="https://www.thewrap.com/george-clooney-midnight-sky/"
                text="https://www.thewrap.com/george-clooney-midnight-sky/" />
            <LinkExternal href="https://lobste.rs/" text="https://lobste.rs/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25552923#25555031"
                text="https://news.ycombinator.com/item?id=25552923#25555031" />
            <LinkExternal href="https://thefinancialdiet.com/20-quick-easy-dinner-recipes-for-people-who-hate-cooking/"
                text="https://thefinancialdiet.com/20-quick-easy-dinner-recipes-for-people-who-hate-cooking/" />
            <LinkExternal
                href="https://www.hanselman.com/blog/scott-hanselmans-2021-ultimate-developer-and-power-users-tool-list-for-windows"
                text="https://www.hanselman.com/blog/scott-hanselmans-2021-ultimate-developer-and-power-users-tool-list-for-windows" />
            <LinkExternal href="https://steelkiwi.com/blog/software-development-team-structure/"
                text="https://steelkiwi.com/blog/software-development-team-structure/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25525590"
                text="https://news.ycombinator.com/item?id=25525590" />
            <LinkExternal
                href="https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs"
                text="https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs" />
            <LinkExternal href="https://www.lesswrong.com/posts/7hFeMWC6Y5eaSixbD/100-tips-for-a-better-life"
                text="https://www.lesswrong.com/posts/7hFeMWC6Y5eaSixbD/100-tips-for-a-better-life" />
            <LinkExternal href="https://interrupt.memfault.com/blog/defensive-and-offensive-programming"
                text="https://interrupt.memfault.com/blog/defensive-and-offensive-programming" />
            <LinkExternal href="https://martinrue.com/my-engineering-axioms/"
                text="https://martinrue.com/my-engineering-axioms/" />
            <LinkExternal href="https://pimbook.org/" text="https://pimbook.org/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25475037"
                text="https://news.ycombinator.com/item?id=25475037" />
            <LinkExternal href="https://dana.org/article/stimulating-consciousness/"
                text="https://dana.org/article/stimulating-consciousness/" />
            <LinkExternal href="http://www.inf.ed.ac.uk/teaching/courses/seoc/2005_2006/resources/statecharts.pdf"
                text="http://www.inf.ed.ac.uk/teaching/courses/seoc/2005_2006/resources/statecharts.pdf" />
            <LinkExternal href="https://bigthink.com/culture-religion/vonnegut-shapes?rebelltitem=4#rebelltitem4"
                text="https://bigthink.com/culture-religion/vonnegut-shapes?rebelltitem=4#rebelltitem4" />
            <LinkExternal href="https://www.overclock3d.net/news/systems/how_powerful_is_the_xbox_one_x/1"
                text="https://www.overclock3d.net/news/systems/how_powerful_is_the_xbox_one_x/1" />
            <LinkExternal href="https://www.redblobgames.com/grids/hexagons/"
                text="https://www.redblobgames.com/grids/hexagons/" />
            <LinkExternal href="https://opticos.github.io/gwsl/" text="https://opticos.github.io/gwsl/" />
            <LinkExternal href="https://www.sleepadvisor.org/waking-up-in-the-middle-of-the-night/"
                text="https://www.sleepadvisor.org/waking-up-in-the-middle-of-the-night/" />
            <LinkExternal
                href="https://superuser.com/questions/1578861/what-is-the-highest-rdp-protocol-version-implemented-by-remmina-freerdp"
                text="https://superuser.com/questions/1578861/what-is-the-highest-rdp-protocol-version-implemented-by-remmina-freerdp" />
            <LinkExternal href="https://github.com/FreeRDP/FreeRDP/issues/3609"
                text="https://github.com/FreeRDP/FreeRDP/issues/3609" />
            <LinkExternal
                href="https://docs.microsoft.com/en-us/previous-versions/windows/it-pro/windows-server-2008-R2-and-2008/ff817580(v=ws.10)?redirectedfrom=MSDN"
                text="https://docs.microsoft.com/en-us/previous-versions/windows/it-pro/windows-server-2008-R2-and-2008/ff817580(v=ws.10)?redirectedfrom=MSDN" />
            <LinkExternal
                href="https://social.technet.microsoft.com/wiki/contents/articles/16652.remotefx-vgpu-setup-and-configuration-guide-for-windows-server-2012.aspx#Requirements_for_RemoteFX_vGPU"
                text="https://social.technet.microsoft.com/wiki/contents/articles/16652.remotefx-vgpu-setup-and-configuration-guide-for-windows-server-2012.aspx#Requirements_for_RemoteFX_vGPU" />
            <LinkExternal href="https://www.freerdp.com/" text="https://www.freerdp.com/" />
            <LinkExternal
                href="https://superuser.com/questions/1533492/windows-10-remote-desktop-with-remotefx-and-hardware-h-264-using-nvidia-nvenc"
                text="https://superuser.com/questions/1533492/windows-10-remote-desktop-with-remotefx-and-hardware-h-264-using-nvidia-nvenc" />
            <LinkExternal href="https://github.com/FreeRDP/FreeRDP/wiki"
                text="https://github.com/FreeRDP/FreeRDP/wiki" />
            <LinkExternal
                href="https://github.com/awakecoding/FreeRDP-Manuals/blob/master/User/FreeRDP-User-Manual.markdown"
                text="https://github.com/awakecoding/FreeRDP-Manuals/blob/master/User/FreeRDP-User-Manual.markdown" />
            <LinkExternal href="https://github.com/sidusnare/utilities/blob/master/myrdesktop"
                text="https://github.com/sidusnare/utilities/blob/master/myrdesktop" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25589177"
                text="https://news.ycombinator.com/item?id=25589177" />
            <LinkExternal href="https://sudhir.io/the-big-little-guide-to-message-queues/"
                text="https://sudhir.io/the-big-little-guide-to-message-queues/" />
            <LinkExternal href="https://lobste.rs/" text="https://lobste.rs/" />
            <LinkExternal href="https://tvtropes.org/pmwiki/pmwiki.php/Main/Flanderization"
                text="https://tvtropes.org/pmwiki/pmwiki.php/Main/Flanderization" />
            <LinkExternal
                href="https://www.psychnewsdaily.com/lonely-people-have-a-unique-brain-signature-perhaps-due-to-so-much-imagined-social-contact/"
                text="https://www.psychnewsdaily.com/lonely-people-have-a-unique-brain-signature-perhaps-due-to-so-much-imagined-social-contact/" />
            <LinkExternal href="https://news.mit.edu/2020/brain-reading-computer-code-1215"
                text="https://news.mit.edu/2020/brain-reading-computer-code-1215" />
            <LinkExternal href="https://yanchware.com/content/fractal-arch-iac"
                text="https://yanchware.com/content/fractal-arch-iac" />
            <LinkExternal href="https://www.nytimes.com/2020/12/08/opinion/delivery-apps-restaurants-fees.html"
                text="https://www.nytimes.com/2020/12/08/opinion/delivery-apps-restaurants-fees.html" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25340425"
                text="https://news.ycombinator.com/item?id=25340425" />
            <LinkExternal
                href="https://www.quantamagazine.org/catherine-dulac-finds-brain-circuitry-behind-sex-specific-behaviors-20201214/"
                text="https://www.quantamagazine.org/catherine-dulac-finds-brain-circuitry-behind-sex-specific-behaviors-20201214/" />
            <LinkExternal href="https://riskfirst.org/" text="https://riskfirst.org/" />
            <LinkExternal href="https://graphics.reuters.com/HEALTH-CORONAVIRUS/EVOLUTION/yxmpjqkdzvr/index.html"
                text="https://graphics.reuters.com/HEALTH-CORONAVIRUS/EVOLUTION/yxmpjqkdzvr/index.html" />
            <LinkExternal href="https://www.mojeek.com/" text="https://www.mojeek.com/" />
            <LinkExternal href="https://www.redblobgames.com/grids/hexagons/"
                text="https://www.redblobgames.com/grids/hexagons/" />
            <LinkExternal href="http://easynetq.com/" text="http://easynetq.com/" />
            <LinkExternal
                href="https://www.quantamagazine.org/the-busy-beaver-game-illuminates-the-fundamental-limits-of-math-20201210/"
                text="https://www.quantamagazine.org/the-busy-beaver-game-illuminates-the-fundamental-limits-of-math-20201210/" />
            <LinkExternal href="https://aeon.co/essays/what-does-it-mean-to-consider-yourself-a-disabled-person"
                text="https://aeon.co/essays/what-does-it-mean-to-consider-yourself-a-disabled-person" />
            <LinkExternal href="https://justprivacy.org/google-alternatives/"
                text="https://justprivacy.org/google-alternatives/" />
            <LinkExternal
                href="https://fortune.com/2020/12/06/offices-covid-workers-returning-never-want-to-stats-data-2/"
                text="https://fortune.com/2020/12/06/offices-covid-workers-returning-never-want-to-stats-data-2/" />
            <LinkExternal href="https://www.nfx.com/post/4-signs-world-class-team/"
                text="https://www.nfx.com/post/4-signs-world-class-team/" />
            <LinkExternal href="https://mitpress.mit.edu/books/software-design-flexibility"
                text="https://mitpress.mit.edu/books/software-design-flexibility" />
            <LinkExternal
                href="https://techcommunity.microsoft.com/t5/excel-blog/announcing-lambda-turn-excel-formulas-into-custom-functions/ba-p/1925546"
                text="https://techcommunity.microsoft.com/t5/excel-blog/announcing-lambda-turn-excel-formulas-into-custom-functions/ba-p/1925546" />
            <LinkExternal href="https://www.duckietown.org/mooc" text="https://www.duckietown.org/mooc" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25341221"
                text="https://news.ycombinator.com/item?id=25341221" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25354253"
                text="https://news.ycombinator.com/item?id=25354253" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25347059"
                text="https://news.ycombinator.com/item?id=25347059" />
            <LinkExternal href="https://typewise.app/?ref=hackernews" text="https://typewise.app/?ref=hackernews" />
            <LinkExternal
                href="https://msrc-blog.microsoft.com/2020/12/31/microsoft-internal-solorigate-investigation-update/"
                text="https://msrc-blog.microsoft.com/2020/12/31/microsoft-internal-solorigate-investigation-update/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25595416"
                text="https://news.ycombinator.com/item?id=25595416" />
            <LinkExternal href="https://ask-jerry.every.to/p/follow-your-curiosity-read-your-ass"
                text="https://ask-jerry.every.to/p/follow-your-curiosity-read-your-ass" />
            <LinkExternal href="https://blog.cloudflare.com/oblivious-dns/"
                text="https://blog.cloudflare.com/oblivious-dns/" />
            <LinkExternal href="https://altwork.com/pages/gallery" text="https://altwork.com/pages/gallery" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25347958#25352246"
                text="https://news.ycombinator.com/item?id=25347958#25352246" />
            <LinkExternal href="https://github.com/linkedin/school-of-sre"
                text="https://github.com/linkedin/school-of-sre" />
            <LinkExternal href="https://futurism.com/neural-network-draw-doom-guy-high-res"
                text="https://futurism.com/neural-network-draw-doom-guy-high-res" />
            <LinkExternal href="https://www.focalityapp.com/en/resources/time-management-strategies/"
                text="https://www.focalityapp.com/en/resources/time-management-strategies/" />
            <LinkExternal href="https://security.christmas/2020/6" text="https://security.christmas/2020/6" />
            <LinkExternal href="https://www.thriftythoughts.io/fire-movement/"
                text="https://www.thriftythoughts.io/fire-movement/" />
            <LinkExternal
                href="https://www.cambridge.org/core/books/foundations-of-probabilistic-programming/819623B1B5B33836476618AC0621F0EE"
                text="https://www.cambridge.org/core/books/foundations-of-probabilistic-programming/819623B1B5B33836476618AC0621F0EE" />
            <LinkExternal
                href="https://blogs.scientificamerican.com/guest-blog/to-what-extent-do-we-see-with-mathematics/"
                text="https://blogs.scientificamerican.com/guest-blog/to-what-extent-do-we-see-with-mathematics/" />
            <LinkExternal
                href="https://www.cia.gov/library/center-for-the-study-of-intelligence/csi-publications/books-and-monographs/psychology-of-intelligence-analysis/art4.html"
                text="https://www.cia.gov/library/center-for-the-study-of-intelligence/csi-publications/books-and-monographs/psychology-of-intelligence-analysis/art4.html" />
            <LinkExternal href="https://hackaday.com/2020/11/25/enhance-is-now-a-thing-but-dont-believe-what-you-see/"
                text="https://hackaday.com/2020/11/25/enhance-is-now-a-thing-but-dont-believe-what-you-see/" />
            <LinkExternal href="http://paulgraham.com/think.html" text="http://paulgraham.com/think.html" />
            <LinkExternal href="https://ipadlinux.org/" text="https://ipadlinux.org/" />
            <LinkExternal href="https://openmeetings.apache.org/index.html"
                text="https://openmeetings.apache.org/index.html" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25169761"
                text="https://news.ycombinator.com/item?id=25169761" />
            <LinkExternal
                href="https://firstround.com/review/the-first-time-founders-guide-to-learning-everything-the-hard-way/"
                text="https://firstround.com/review/the-first-time-founders-guide-to-learning-everything-the-hard-way/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25170661"
                text="https://news.ycombinator.com/item?id=25170661" />
            <LinkExternal href="https://jsomers.net/i-should-have-loved-biology/"
                text="https://jsomers.net/i-should-have-loved-biology/" />
            <LinkExternal href="https://brailleinstitute.org/freefont" text="https://brailleinstitute.org/freefont" />
            <LinkExternal
                href="https://americanaffairsjournal.org/2020/08/the-knowledge-economy-a-critique-of-the-dominant-view/"
                text="https://americanaffairsjournal.org/2020/08/the-knowledge-economy-a-critique-of-the-dominant-view/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25598029"
                text="https://news.ycombinator.com/item?id=25598029" />
            <LinkExternal href="https://probml.github.io/pml-book/book1.html"
                text="https://probml.github.io/pml-book/book1.html" />
            <LinkExternal
                href="https://medium.com/criteo-labs/net-threadpool-starvation-and-how-queuing-makes-it-worse-512c8d570527"
                text="https://medium.com/criteo-labs/net-threadpool-starvation-and-how-queuing-makes-it-worse-512c8d570527" />
            <LinkExternal href="https://paulosman.me/2019/12/30/production-oriented-development.html"
                text="https://paulosman.me/2019/12/30/production-oriented-development.html" />
            <LinkExternal href="https://www.outsideonline.com/2401643/life-lessons-97-year-old-lobsterman"
                text="https://www.outsideonline.com/2401643/life-lessons-97-year-old-lobsterman" />
            <LinkExternal
                href="https://www.quantamagazine.org/geometry-reveals-how-the-world-is-assembled-from-cubes-20201119/"
                text="https://www.quantamagazine.org/geometry-reveals-how-the-world-is-assembled-from-cubes-20201119/" />
            <LinkExternal
                href="https://lakatos.medium.com/10-things-i-wish-id-learned-sooner-after-being-a-developer-for-10-years-93681b41f883"
                text="https://lakatos.medium.com/10-things-i-wish-id-learned-sooner-after-being-a-developer-for-10-years-93681b41f883" />
            <LinkExternal href="https://devfonts.gafi.dev/" text="https://devfonts.gafi.dev/" />
            <LinkExternal
                href="https://github.com/tjitze/ranked-programming/blob/master/documentation/ranked_programming.pdf"
                text="https://github.com/tjitze/ranked-programming/blob/master/documentation/ranked_programming.pdf" />
            <LinkExternal href="https://faculty.washington.edu/ajko/papers/Li2019WhatDistinguishesEngineers.pdf"
                text="https://faculty.washington.edu/ajko/papers/Li2019WhatDistinguishesEngineers.pdf" />
            <LinkExternal href="https://healthcare.ascension.org/Doctors/1548436371/amy-m-degueme-mequon-wi"
                text="https://healthcare.ascension.org/Doctors/1548436371/amy-m-degueme-mequon-wi" />
            <LinkExternal
                href="https://ieeexplore.ieee.org/ielx7/6287639/8948470/09186656.pdf?tp=&arnumber=9186656&isnumber=8948470&ref=aHR0cHM6Ly9uZXdzLnljb21iaW5hdG9yLmNvbS8="
                text="https://ieeexplore.ieee.org/ielx7/6287639/8948470/09186656.pdf?tp=&arnumber=9186656&isnumber=8948470&ref=aHR0cHM6Ly9uZXdzLnljb21iaW5hdG9yLmNvbS8=" />
            <LinkExternal href="https://wildbit.com/good-bad/product-manager"
                text="https://wildbit.com/good-bad/product-manager" />
            <LinkExternal href="https://www.narratedbypoise.com/" text="https://www.narratedbypoise.com/" />
            <LinkExternal href="https://pablasso.com/high-performance-individuals-and-teams/"
                text="https://pablasso.com/high-performance-individuals-and-teams/" />
            <LinkExternal href="https://www.brain.fm/" text="https://www.brain.fm/" />
            <LinkExternal href="https://silentlysurrendernomore.home.blog/author/bookshopbabe/"
                text="https://silentlysurrendernomore.home.blog/author/bookshopbabe/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25107353"
                text="https://news.ycombinator.com/item?id=25107353" />
            <LinkExternal href="https://simonwillison.net/2020/Nov/14/personal-data-warehouses/"
                text="https://simonwillison.net/2020/Nov/14/personal-data-warehouses/" />
            <LinkExternal
                href="https://hedgehogreview.com/issues/the-cultural-contradictions-of-modern-science/articles/invisible-science"
                text="https://hedgehogreview.com/issues/the-cultural-contradictions-of-modern-science/articles/invisible-science" />
            <LinkExternal
                href="https://www.plannedparenthood.org/planned-parenthood-wisconsin-inc/patients/gender-affirming-care"
                text="https://www.plannedparenthood.org/planned-parenthood-wisconsin-inc/patients/gender-affirming-care" />
            <LinkExternal href="https://uwm.edu/womens-gender-studies/graduate/"
                text="https://uwm.edu/womens-gender-studies/graduate/" />
            <LinkExternal href="https://uwm.edu/psychology/graduate/m-s-program/"
                text="https://uwm.edu/psychology/graduate/m-s-program/" />
            <LinkExternal href="https://devblogs.microsoft.com/dotnet/announcing-net-5-0/"
                text="https://devblogs.microsoft.com/dotnet/announcing-net-5-0/" />
            <LinkExternal
                href="https://zwbetz.com/attention-is-my-most-valuable-asset-for-productivity-as-a-software-developer/"
                text="https://zwbetz.com/attention-is-my-most-valuable-asset-for-productivity-as-a-software-developer/" />
            <LinkExternal href="https://www.dampfkraft.com/philosophers-keyboard.html"
                text="https://www.dampfkraft.com/philosophers-keyboard.html" />
            <LinkExternal href="https://www.easyuefi.com/wintousb/?AVGAFFILIATE=3305&__c=1"
                text="https://www.easyuefi.com/wintousb/?AVGAFFILIATE=3305&__c=1" />
            <LinkExternal href="https://support.apple.com/airpods-pro-service-program-sound-issues"
                text="https://support.apple.com/airpods-pro-service-program-sound-issues" />
            <LinkExternal href="https://omar.website/tabfs/" text="https://omar.website/tabfs/" />
            <LinkExternal href="http://blog.fogus.me/2020/12/31/the-best-things-and-stuff-of-2020/"
                text="http://blog.fogus.me/2020/12/31/the-best-things-and-stuff-of-2020/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25593828"
                text="https://news.ycombinator.com/item?id=25593828" />
            <LinkExternal href="https://mobile.twitter.com/MarcJBrooker/status/1344779967276597249"
                text="https://mobile.twitter.com/MarcJBrooker/status/1344779967276597249" />
            <LinkExternal href="https://github.com/EleutherAI/gpt-neo" text="https://github.com/EleutherAI/gpt-neo" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25607809"
                text="https://news.ycombinator.com/item?id=25607809" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25591443"
                text="https://news.ycombinator.com/item?id=25591443" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25591443"
                text="https://news.ycombinator.com/item?id=25591443" />
            <LinkExternal href="https://fsharpforfunandprofit.com/about/terms/"
                text="https://fsharpforfunandprofit.com/about/terms/" />
            <LinkExternal href="https://www.apartmenttherapy.com/panasonic-work-from-home-cubicle-36810923"
                text="https://www.apartmenttherapy.com/panasonic-work-from-home-cubicle-36810923" />
            <LinkExternal href="https://pragprog.com/titles/swdddf/domain-modeling-made-functional/"
                text="https://pragprog.com/titles/swdddf/domain-modeling-made-functional/" />
            <LinkExternal href="https://cognitiontoday.com/phone-addiction-coping-solutions-research-statistics/"
                text="https://cognitiontoday.com/phone-addiction-coping-solutions-research-statistics/" />
            <LinkExternal
                href="https://arstechnica.com/video/watch/blade-runner-game-director-louis-castle-extended-interview"
                text="https://arstechnica.com/video/watch/blade-runner-game-director-louis-castle-extended-interview" />
            <LinkExternal href="https://www.sciencedirect.com/science/article/pii/S0378437120305975?via%3Dihub"
                text="https://www.sciencedirect.com/science/article/pii/S0378437120305975?via%3Dihub" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25619627"
                text="https://news.ycombinator.com/item?id=25619627" />
            <LinkExternal href="https://www.acm.org/diversity-inclusion/words-matter"
                text="https://www.acm.org/diversity-inclusion/words-matter" />
            <LinkExternal href="https://news.ycombinator.com/from?site=github.com/dotnet"
                text="https://news.ycombinator.com/from?site=github.com/dotnet" />
            <LinkExternal href="https://martinheinz.dev/blog/40" text="https://martinheinz.dev/blog/40" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25623858"
                text="https://news.ycombinator.com/item?id=25623858" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25624044"
                text="https://news.ycombinator.com/item?id=25624044" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25621596"
                text="https://news.ycombinator.com/item?id=25621596" />
            <LinkExternal
                href="https://github.com/awakecoding/FreeRDP-Manuals/blob/master/User/FreeRDP-User-Manual.markdown"
                text="https://github.com/awakecoding/FreeRDP-Manuals/blob/master/User/FreeRDP-User-Manual.markdown" />
            <LinkExternal href="https://read.cv/kashif" text="https://read.cv/kashif" />
            <LinkExternal
                href="http://patft.uspto.gov/netacgi/nph-Parser?Sect1=PTO1&Sect2=HITOFF&d=PALL&p=1&u=%2Fnetahtml%2FPTO%2Fsrchnum.htm&r=1&f=G&l=50&s1=10853717.PN.&OS=PN/10853717&RS=PN/10853717"
                text="http://patft.uspto.gov/netacgi/nph-Parser?Sect1=PTO1&Sect2=HITOFF&d=PALL&p=1&u=%2Fnetahtml%2FPTO%2Fsrchnum.htm&r=1&f=G&l=50&s1=10853717.PN.&OS=PN/10853717&RS=PN/10853717" />
            <LinkExternal href="https://en.m.wikipedia.org/wiki/Moral_Mazes"
                text="https://en.m.wikipedia.org/wiki/Moral_Mazes" />
            <LinkExternal
                href="https://ocw.mit.edu/courses/electrical-engineering-and-computer-science/6-172-performance-engineering-of-software-systems-fall-2018/"
                text="https://ocw.mit.edu/courses/electrical-engineering-and-computer-science/6-172-performance-engineering-of-software-systems-fall-2018/" />
            <LinkExternal href="https://gitlab.com/CalcProgrammer1/OpenRGB"
                text="https://gitlab.com/CalcProgrammer1/OpenRGB" />
            <LinkExternal href="https://dlorenc.medium.com/whos-at-the-helm-1101c37bf0f1"
                text="https://dlorenc.medium.com/whos-at-the-helm-1101c37bf0f1" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25647753"
                text="https://news.ycombinator.com/item?id=25647753" />
            <LinkExternal
                href="https://arstechnica.com/tech-policy/2021/01/whatsapp-users-must-share-their-data-with-facebook-or-stop-using-the-app/"
                text="https://arstechnica.com/tech-policy/2021/01/whatsapp-users-must-share-their-data-with-facebook-or-stop-using-the-app/" />
            <LinkExternal
                href="https://stackoverflow.com/questions/27753308/how-to-set-permissions-for-apache2-to-let-user-create-or-edit-files-in-var-www"
                text="https://stackoverflow.com/questions/27753308/how-to-set-permissions-for-apache2-to-let-user-create-or-edit-files-in-var-www" />
            <LinkExternal href="http://www.scipy-lectures.org/index.html"
                text="http://www.scipy-lectures.org/index.html" />
            <LinkExternal
                href="https://krebsonsecurity.com/2021/01/sealed-u-s-court-records-exposed-in-solarwinds-breach/"
                text="https://krebsonsecurity.com/2021/01/sealed-u-s-court-records-exposed-in-solarwinds-breach/" />
            <LinkExternal href="https://sahillavingia.com/work" text="https://sahillavingia.com/work" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25673275"
                text="https://news.ycombinator.com/item?id=25673275" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25673275"
                text="https://news.ycombinator.com/item?id=25673275" />
            <LinkExternal href="https://leetresumes.com/examples" text="https://leetresumes.com/examples" />
            <LinkExternal
                href="https://www.jsonline.com/story/news/solutions/2021/01/07/ron-johnson-scott-fitzgerald-tom-tiffany-must-resign-expelled/6585447002/"
                text="https://www.jsonline.com/story/news/solutions/2021/01/07/ron-johnson-scott-fitzgerald-tom-tiffany-must-resign-expelled/6585447002/" />
            <LinkExternal href="https://nolanlawson.com/2021/01/03/options-for-styling-web-components/"
                text="https://nolanlawson.com/2021/01/03/options-for-styling-web-components/" />
            <LinkExternal href="https://www.cs.kent.ac.uk/people/staff/srk21/research/papers/kell20convivial.pdf"
                text="https://www.cs.kent.ac.uk/people/staff/srk21/research/papers/kell20convivial.pdf" />
            <LinkExternal href="https://github.com/gregnb/mui-datatables"
                text="https://github.com/gregnb/mui-datatables" />
            <LinkExternal href="https://github.com/closedxml/closedxml" text="https://github.com/closedxml/closedxml" />
            <LinkExternal href="https://github.com/gregnb/mui-datatables"
                text="https://github.com/gregnb/mui-datatables" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25687552"
                text="https://news.ycombinator.com/item?id=25687552" />
            <LinkExternal href="https://getsession.org/session-protocol-technical-information/"
                text="https://getsession.org/session-protocol-technical-information/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25698750"
                text="https://news.ycombinator.com/item?id=25698750" />
            <LinkExternal href="https://thesmartcoder.dev/books/" text="https://thesmartcoder.dev/books/" />
            <LinkExternal
                href="https://www.fastcompany.com/90501691/science-confirms-it-web-sites-really-do-all-look-the-same"
                text="https://www.fastcompany.com/90501691/science-confirms-it-web-sites-really-do-all-look-the-same" />
            <LinkExternal
                href="https://www.inc.com/minda-zetlin/elon-musk-worlds-richest-man-tweet-response-emotional-intelligence.html"
                text="https://www.inc.com/minda-zetlin/elon-musk-worlds-richest-man-tweet-response-emotional-intelligence.html" />
            <LinkExternal
                href="https://www.sprintphysio.co.uk/patient-exercises/back-and-neck/deep-neck-flexor-exercises.html"
                text="https://www.sprintphysio.co.uk/patient-exercises/back-and-neck/deep-neck-flexor-exercises.html" />
            <LinkExternal href="https://peppe8o.com/manage-your-home-stocks-like-a-pro-with-grocy-and-raspberry-pi/"
                text="https://peppe8o.com/manage-your-home-stocks-like-a-pro-with-grocy-and-raspberry-pi/" />
            <LinkExternal href="https://philhawthorne.com/automating-your-shopping-list-with-home-assistant-and-grocy/"
                text="https://philhawthorne.com/automating-your-shopping-list-with-home-assistant-and-grocy/" />
            <LinkExternal href="https://routinehub.co/shortcut/7061/" text="https://routinehub.co/shortcut/7061/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25706993"
                text="https://news.ycombinator.com/item?id=25706993" />
            <LinkExternal href="https://www.bbc.com/reel/video/p090s793/advice-from-a-104-year-old-phd-student"
                text="https://www.bbc.com/reel/video/p090s793/advice-from-a-104-year-old-phd-student" />
            <LinkExternal
                href="https://www.fastcompany.com/90501691/science-confirms-it-web-sites-really-do-all-look-the-same"
                text="https://www.fastcompany.com/90501691/science-confirms-it-web-sites-really-do-all-look-the-same" />
            <LinkExternal href="https://thesmartcoder.dev/books/" text="https://thesmartcoder.dev/books/" />
            <LinkExternal href="https://convier.me/" text="https://convier.me/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25696842"
                text="https://news.ycombinator.com/item?id=25696842" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25687552"
                text="https://news.ycombinator.com/item?id=25687552" />
            <LinkExternal href="https://www.jvt.me/posts/2021/01/05/why-content-negotiation/"
                text="https://www.jvt.me/posts/2021/01/05/why-content-negotiation/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25643940"
                text="https://news.ycombinator.com/item?id=25643940" />
            <LinkExternal href="https://www.bti360.com/what-ive-learned-in-45-years-in-the-software-industry/"
                text="https://www.bti360.com/what-ive-learned-in-45-years-in-the-software-industry/" />
            <LinkExternal href="https://rachelbythebay.com/w/2021/01/04/dns/"
                text="https://rachelbythebay.com/w/2021/01/04/dns/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25640411"
                text="https://news.ycombinator.com/item?id=25640411" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25634192"
                text="https://news.ycombinator.com/item?id=25634192" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25641518"
                text="https://news.ycombinator.com/item?id=25641518" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25612786"
                text="https://news.ycombinator.com/item?id=25612786" />
            <LinkExternal href="https://read.cv/kashif" text="https://read.cv/kashif" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25623010"
                text="https://news.ycombinator.com/item?id=25623010" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25628729"
                text="https://news.ycombinator.com/item?id=25628729" />
            <LinkExternal
                href="https://ocw.mit.edu/courses/electrical-engineering-and-computer-science/6-172-performance-engineering-of-software-systems-fall-2018/"
                text="https://ocw.mit.edu/courses/electrical-engineering-and-computer-science/6-172-performance-engineering-of-software-systems-fall-2018/" />
            <LinkExternal href="https://www.twilio.com/docs/voice/twiml/say/text-speech"
                text="https://www.twilio.com/docs/voice/twiml/say/text-speech" />
            <LinkExternal href="https://brailleinstitute.org/freefont" text="https://brailleinstitute.org/freefont" />
            <LinkExternal href="https://devfonts.gafi.dev/" text="https://devfonts.gafi.dev/" />
            <LinkExternal
                href="https://www.nvidia.com/en-us/geforce-now/unsupported-device/?jso=eyJQbHQiOiJicm93c2VyIn0="
                text="https://www.nvidia.com/en-us/geforce-now/unsupported-device/?jso=eyJQbHQiOiJicm93c2VyIn0=" />
            <LinkExternal href="https://reesew.io/posts/react-algebraic-effects"
                text="https://reesew.io/posts/react-algebraic-effects" />
            <LinkExternal href="https://science.sciencemag.org/content/370/6516/530"
                text="https://science.sciencemag.org/content/370/6516/530" />
            <LinkExternal href="https://github.com/florinpop17/app-ideas"
                text="https://github.com/florinpop17/app-ideas" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24959238"
                text="https://news.ycombinator.com/item?id=24959238" />
            <LinkExternal
                href="https://www.quantamagazine.org/the-black-hole-information-paradox-comes-to-an-end-20201029/"
                text="https://www.quantamagazine.org/the-black-hole-information-paradox-comes-to-an-end-20201029/" />
            <LinkExternal href="http://exodos.the-eye.us/" text="http://exodos.the-eye.us/" />
            <LinkExternal href="https://timilearning.com/" text="https://timilearning.com/" />
            <LinkExternal href="https://www.hanselman.com/blog/how-to-deal-with-extreme-physical-pain"
                text="https://www.hanselman.com/blog/how-to-deal-with-extreme-physical-pain" />
            <LinkExternal href="https://cmu-313.github.io/" text="https://cmu-313.github.io/" />
            <LinkExternal href="https://sreteams.substack.com/p/hash" text="https://sreteams.substack.com/p/hash" />
            <LinkExternal href="https://nohq.co/blog/the-remote-work-tools-wed-love-to-see-in-2021/"
                text="https://nohq.co/blog/the-remote-work-tools-wed-love-to-see-in-2021/" />
            <LinkExternal href="https://danuker.go.ro/the-grand-unified-theory-of-software-architecture.html"
                text="https://danuker.go.ro/the-grand-unified-theory-of-software-architecture.html" />
            <LinkExternal
                href="https://www.psychologytoday.com/us/blog/friendship-20/201907/what-is-rejection-sensitive-dysphoria"
                text="https://www.psychologytoday.com/us/blog/friendship-20/201907/what-is-rejection-sensitive-dysphoria" />
            <LinkExternal href="https://didshejourney.com/" text="https://didshejourney.com/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24892599"
                text="https://news.ycombinator.com/item?id=24892599" />
            <LinkExternal href="https://opguides.info/" text="https://opguides.info/" />
            <LinkExternal href="https://markjames.dev/2020-10-25-developing-a-cli-music-player-csharp/"
                text="https://markjames.dev/2020-10-25-developing-a-cli-music-player-csharp/" />
            <LinkExternal href="http://bofh.bjash.com/bofh/bofh2.html" text="http://bofh.bjash.com/bofh/bofh2.html" />
            <LinkExternal href="http://www.mit.edu/~xela/tao.html" text="http://www.mit.edu/~xela/tao.html" />
            <LinkExternal href="https://docs.openproject.org/release-notes/11-0-0/"
                text="https://docs.openproject.org/release-notes/11-0-0/" />
            <LinkExternal href="https://knowablemagazine.org/article/technology/2020/what-is-neurosymbolic-ai"
                text="https://knowablemagazine.org/article/technology/2020/what-is-neurosymbolic-ai" />
            <LinkExternal href="https://www.theverge.com/21507966/foxconn-empty-factories-wisconsin-jobs-loophole-trump"
                text="https://www.theverge.com/21507966/foxconn-empty-factories-wisconsin-jobs-loophole-trump" />
            <LinkExternal href="https://www.theverge.com/21507966/foxconn-empty-factories-wisconsin-jobs-loophole-trump"
                text="https://www.theverge.com/21507966/foxconn-empty-factories-wisconsin-jobs-loophole-trump" />
            <LinkExternal
                href="https://iamevan.me/categories/sre/psychological-safety-and-the-only-pyramid-scheme-that-works/"
                text="https://iamevan.me/categories/sre/psychological-safety-and-the-only-pyramid-scheme-that-works/" />
            <LinkExternal
                href="https://charleshughsmith.blogspot.com/2020/10/how-we-institutionalized-incompetence.html?m=1"
                text="https://charleshughsmith.blogspot.com/2020/10/how-we-institutionalized-incompetence.html?m=1" />
            <LinkExternal href="https://www.pnas.org/content/111/20/7379"
                text="https://www.pnas.org/content/111/20/7379" />
            <LinkExternal href="http://blog.rlmflores.me/2020/10/14/what_is_expected_of_an_engineering_manager/"
                text="http://blog.rlmflores.me/2020/10/14/what_is_expected_of_an_engineering_manager/" />
            <LinkExternal href="https://www.drmaciver.com/2013/03/what-the-fuck-are-we-doing/"
                text="https://www.drmaciver.com/2013/03/what-the-fuck-are-we-doing/" />
            <LinkExternal href="https://adlrocha.substack.com/p/adlrocha-what-if-we-had-local-first"
                text="https://adlrocha.substack.com/p/adlrocha-what-if-we-had-local-first" />
            <LinkExternal
                href="https://www.brookings.edu/techstream/why-congress-should-invest-in-open-source-software/"
                text="https://www.brookings.edu/techstream/why-congress-should-invest-in-open-source-software/" />
            <LinkExternal href="https://ibnarabisociety.org/theophanies-and-lights-osman-yahya/"
                text="https://ibnarabisociety.org/theophanies-and-lights-osman-yahya/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24762734"
                text="https://news.ycombinator.com/item?id=24762734" />
            <LinkExternal href="https://github.com/cool-RR/grid_royale" text="https://github.com/cool-RR/grid_royale" />
            <LinkExternal href="https://blog.eyas.sh/2020/10/unity-for-engineers-pt1-basic-concepts/"
                text="https://blog.eyas.sh/2020/10/unity-for-engineers-pt1-basic-concepts/" />
            <LinkExternal href="http://bwlampson.site/58-Consensus/WebPage.html"
                text="http://bwlampson.site/58-Consensus/WebPage.html" />
            <LinkExternal href="https://typicalprogrammer.com/what-does-code-readability-mean"
                text="https://typicalprogrammer.com/what-does-code-readability-mean" />
            <LinkExternal href="https://kartick.substack.com/p/better-to-be-too-early-to-a-market"
                text="https://kartick.substack.com/p/better-to-be-too-early-to-a-market" />
            <LinkExternal href="https://juliamono.netlify.app/" text="https://juliamono.netlify.app/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24725693"
                text="https://news.ycombinator.com/item?id=24725693" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24731010"
                text="https://news.ycombinator.com/item?id=24731010" />
            <LinkExternal
                href="https://www.vice.com/en/article/ep4ykn/a-thousand-years-before-darwin-islamic-scholars-were-writing-about-natural-selection"
                text="https://www.vice.com/en/article/ep4ykn/a-thousand-years-before-darwin-islamic-scholars-were-writing-about-natural-selection" />
            <LinkExternal
                href="https://www.commondreams.org/views/2020/01/21/if-worker-pay-had-kept-pace-productivity-gains-1968-todays-minimum-wage-would-be-24"
                text="https://www.commondreams.org/views/2020/01/21/if-worker-pay-had-kept-pace-productivity-gains-1968-todays-minimum-wage-would-be-24" />
            <LinkExternal
                href="https://www.prospectmagazine.co.uk/magazine/entangled-life-book-review-mushrooms-fungi-biology-science"
                text="https://www.prospectmagazine.co.uk/magazine/entangled-life-book-review-mushrooms-fungi-biology-science" />
            <LinkExternal
                href="https://www.theverge.com/2020/10/9/21508964/microsoft-remote-work-from-home-covid-19-coronavirus"
                text="https://www.theverge.com/2020/10/9/21508964/microsoft-remote-work-from-home-covid-19-coronavirus" />
            <LinkExternal
                href="https://obie.medium.com/react-is-dead-long-live-reactive-rails-long-live-stimulusreflex-and-viewcomponent-cd061e2b0fe2"
                text="https://obie.medium.com/react-is-dead-long-live-reactive-rails-long-live-stimulusreflex-and-viewcomponent-cd061e2b0fe2" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24716562"
                text="https://news.ycombinator.com/item?id=24716562" />
            <LinkExternal href="https://linearb.io/blog/dev-lead-feature-update/"
                text="https://linearb.io/blog/dev-lead-feature-update/" />
            <LinkExternal href="https://www.smashingmagazine.com/2020/10/developing-semantic-web/"
                text="https://www.smashingmagazine.com/2020/10/developing-semantic-web/" />
            <LinkExternal href="https://stdw.github.io/cm-sdr/" text="https://stdw.github.io/cm-sdr/" />
            <LinkExternal href="https://openspace.sfmoma.org/2012/07/a-meditation-on-space-in-four-parts/"
                text="https://openspace.sfmoma.org/2012/07/a-meditation-on-space-in-four-parts/" />
            <LinkExternal href="https://chrispenner.ca/posts/traversal-systems"
                text="https://chrispenner.ca/posts/traversal-systems" />
            <LinkExternal href="https://samcurry.net/hacking-apple/" text="https://samcurry.net/hacking-apple/" />
            <LinkExternal href="https://github.com/xwmx/nb" text="https://github.com/xwmx/nb" />
            <LinkExternal href="https://www.buyforlife.com/" text="https://www.buyforlife.com/" />
            <LinkExternal href="https://www.alextsun.com/files/Prob_Stat_for_CS_Book.pdf"
                text="https://www.alextsun.com/files/Prob_Stat_for_CS_Book.pdf" />
            <LinkExternal href="https://astrosociety.org/file_download/inline/621a63fc-04d5-4794-8d2b-38e7195056e9"
                text="https://astrosociety.org/file_download/inline/621a63fc-04d5-4794-8d2b-38e7195056e9" />
            <LinkExternal href="https://blog.klipse.tech/databook/2020/09/29/do-principles.html?essence"
                text="https://blog.klipse.tech/databook/2020/09/29/do-principles.html?essence" />
            <LinkExternal href="https://blog.luap.info/drafts/i-built-a-lay-down-desk.html?hnn"
                text="https://blog.luap.info/drafts/i-built-a-lay-down-desk.html?hnn" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24670746"
                text="https://news.ycombinator.com/item?id=24670746" />
            <LinkExternal href="https://github.com/nakabonne/ali" text="https://github.com/nakabonne/ali" />
            <LinkExternal href="https://www.seangoedecke.com/worry-driven-development/"
                text="https://www.seangoedecke.com/worry-driven-development/" />
            <LinkExternal href="http://m.nautil.us/issue/90/something-green/how-psilocybin-can-save-the-environment"
                text="http://m.nautil.us/issue/90/something-green/how-psilocybin-can-save-the-environment" />
            <LinkExternal href="https://tromp.github.io/cl/diagrams.html"
                text="https://tromp.github.io/cl/diagrams.html" />
            <LinkExternal
                href="https://www.strongtowns.org/journal/2017/1/10/poor-neighborhoods-make-the-best-investment"
                text="https://www.strongtowns.org/journal/2017/1/10/poor-neighborhoods-make-the-best-investment" />
            <LinkExternal href="https://hbr.org/2020/09/research-why-breathing-is-so-effective-at-reducing-stress"
                text="https://hbr.org/2020/09/research-why-breathing-is-so-effective-at-reducing-stress" />
            <LinkExternal href="https://cra.mr/an-honest-review-of-gatsby/"
                text="https://cra.mr/an-honest-review-of-gatsby/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24671019"
                text="https://news.ycombinator.com/item?id=24671019" />
            <LinkExternal href="https://www.newyorker.com/science/maria-konnikova/what-makes-people-feel-upbeat-at-work"
                text="https://www.newyorker.com/science/maria-konnikova/what-makes-people-feel-upbeat-at-work" />
            <LinkExternal
                href="https://www.quantamagazine.org/reasons-revealed-for-the-brains-elastic-sense-of-time-20200924/"
                text="https://www.quantamagazine.org/reasons-revealed-for-the-brains-elastic-sense-of-time-20200924/" />
            <LinkExternal
                href="https://www.inc.com/marcel-schwantes/warren-buffett-says-this-is-1-simple-habit-that-separates-successful-people-from-everyone-else.html"
                text="https://www.inc.com/marcel-schwantes/warren-buffett-says-this-is-1-simple-habit-that-separates-successful-people-from-everyone-else.html" />
            <LinkExternal
                href="https://www.scientificamerican.com/article/we-learn-faster-when-we-arent-told-what-choices-to-make/"
                text="https://www.scientificamerican.com/article/we-learn-faster-when-we-arent-told-what-choices-to-make/" />
            <LinkExternal href="http://www.pathsensitive.com/?m=1" text="http://www.pathsensitive.com/?m=1" />
            <LinkExternal
                href="https://blokable.com/news/the-housing-market-is-building-snowflakes-an-endless-series-of-one-offs/"
                text="https://blokable.com/news/the-housing-market-is-building-snowflakes-an-endless-series-of-one-offs/" />
            <LinkExternal href="https://www.openpim.org/" text="https://www.openpim.org/" />
            <LinkExternal href="https://ludism.org/mentat/" text="https://ludism.org/mentat/" />
            <LinkExternal
                href="https://www.quantamagazine.org/complexity-scientist-beats-traffic-jams-through-adaptation-20200928/"
                text="https://www.quantamagazine.org/complexity-scientist-beats-traffic-jams-through-adaptation-20200928/" />
            <LinkExternal href="http://aosabook.org/en/index.html" text="http://aosabook.org/en/index.html" />
            <LinkExternal href="https://seeing-theory.brown.edu/index.html"
                text="https://seeing-theory.brown.edu/index.html" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24605949"
                text="https://news.ycombinator.com/item?id=24605949" />
            <LinkExternal href="https://seths.blog/2020/09/when-can-we-talk-about-your-system/"
                text="https://seths.blog/2020/09/when-can-we-talk-about-your-system/" />
            <LinkExternal href="https://csnotes.me/" text="https://csnotes.me/" />
            <LinkExternal href="https://foundation.mozilla.org/en/blog/new-open-source-tool-tracking-disinformation/"
                text="https://foundation.mozilla.org/en/blog/new-open-source-tool-tracking-disinformation/" />
            <LinkExternal
                href="https://www.economist.com/briefing/2020/09/26/the-covid-19-pandemic-is-worse-than-official-figures-show"
                text="https://www.economist.com/briefing/2020/09/26/the-covid-19-pandemic-is-worse-than-official-figures-show" />
            <LinkExternal
                href="https://pointersgonewild.com/2020/09/22/the-need-for-stable-foundations-in-software-development/"
                text="https://pointersgonewild.com/2020/09/22/the-need-for-stable-foundations-in-software-development/" />
            <LinkExternal href="https://www.additudemag.com/rejection-sensitive-dysphoria-and-adhd/"
                text="https://www.additudemag.com/rejection-sensitive-dysphoria-and-adhd/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24591418"
                text="https://news.ycombinator.com/item?id=24591418" />
            <LinkExternal
                href="https://www.bbc.com/future/article/20150630-my-dentist-saved-my-tooth-but-stole-my-memory"
                text="https://www.bbc.com/future/article/20150630-my-dentist-saved-my-tooth-but-stole-my-memory" />
            <LinkExternal href="https://louwrentius.com/most-technical-debt-is-just-bullshit.html"
                text="https://louwrentius.com/most-technical-debt-is-just-bullshit.html" />
            <LinkExternal href="https://blog.codegiant.io/programming-skills-d77d4abdf255"
                text="https://blog.codegiant.io/programming-skills-d77d4abdf255" />
            <LinkExternal href="https://math.recipes/Math-recipes.pdf" text="https://math.recipes/Math-recipes.pdf" />
            <LinkExternal href="https://karimfanous.substack.com/p/on-developer-productivity-flow-to"
                text="https://karimfanous.substack.com/p/on-developer-productivity-flow-to" />
            <LinkExternal href="https://staffeng.com/guides/work-on-what-matters"
                text="https://staffeng.com/guides/work-on-what-matters" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24593776"
                text="https://news.ycombinator.com/item?id=24593776" />
            <LinkExternal
                href="https://www.npr.org/2020/09/15/913207918/how-to-say-no-for-the-people-pleaser-who-always-says-yes"
                text="https://www.npr.org/2020/09/15/913207918/how-to-say-no-for-the-people-pleaser-who-always-says-yes" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24571137"
                text="https://news.ycombinator.com/item?id=24571137" />
            <LinkExternal
                href="https://dropbox.tech/frontend/the-software-engineering-lifecycle--how-we-built-the-new-dropbox?hn=1"
                text="https://dropbox.tech/frontend/the-software-engineering-lifecycle--how-we-built-the-new-dropbox?hn=1" />
            <LinkExternal href="https://prog21.dadgum.com/56.html" text="https://prog21.dadgum.com/56.html" />
            <LinkExternal
                href="https://medium.com/software-safety/a-software-engineers-guide-to-cybernetics-d57c7def1453"
                text="https://medium.com/software-safety/a-software-engineers-guide-to-cybernetics-d57c7def1453" />
            <LinkExternal href="https://bash-prompt.net/guides/pulse-audio-bluetooth-streaming/"
                text="https://bash-prompt.net/guides/pulse-audio-bluetooth-streaming/" />
            <LinkExternal
                href="https://medium.com/@robertwiblin/ugh-fields-or-why-you-can-t-even-bear-to-think-about-that-task-5941837dac62"
                text="https://medium.com/@robertwiblin/ugh-fields-or-why-you-can-t-even-bear-to-think-about-that-task-5941837dac62" />
            <LinkExternal
                href="https://www.bbc.com/worklife/article/20200827-why-in-person-leaders-may-not-be-the-best-virtual-ones"
                text="https://www.bbc.com/worklife/article/20200827-why-in-person-leaders-may-not-be-the-best-virtual-ones" />
            <LinkExternal href="https://topology.mitpress.mit.edu/" text="https://topology.mitpress.mit.edu/" />
            <LinkExternal href="https://github.com/kdeldycke/awesome-falsehood"
                text="https://github.com/kdeldycke/awesome-falsehood" />
            <LinkExternal href="https://thevaluable.dev/guide-debate-software-developer-skill/"
                text="https://thevaluable.dev/guide-debate-software-developer-skill/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=24397272"
                text="https://news.ycombinator.com/item?id=24397272" />
            <LinkExternal href="https://neilkakkar.com/things-I-learned-to-become-a-senior-software-engineer.html"
                text="https://neilkakkar.com/things-I-learned-to-become-a-senior-software-engineer.html" />
            <LinkExternal href="http://brendanfong.com/programmingcats.html"
                text="http://brendanfong.com/programmingcats.html" />
            <LinkExternal href="https://www.neoaxis.com/news/neoaxis_engine_2020_5_released"
                text="https://www.neoaxis.com/news/neoaxis_engine_2020_5_released" />
            <LinkExternal
                href="https://softwarebyscience.com/very-short-functions-are-a-code-smell-an-overview-of-the-science-on-function-length/"
                text="https://softwarebyscience.com/very-short-functions-are-a-code-smell-an-overview-of-the-science-on-function-length/" />
            <LinkExternal href="https://nocomplexity.com/documents/arplaybook/software-architecture.html"
                text="https://nocomplexity.com/documents/arplaybook/software-architecture.html" />
            <LinkExternal href="https://tilde.town/" text="https://tilde.town/" />
            <LinkExternal href="https://www.openbot.org/" text="https://www.openbot.org/" />
            <LinkExternal href="https://meiert.com/en/blog/minimal-web-development/"
                text="https://meiert.com/en/blog/minimal-web-development/" />
            <LinkExternal href="https://msadowski.github.io/2-years-remote-consulting/"
                text="https://msadowski.github.io/2-years-remote-consulting/" />
            <LinkExternal href="https://amanjain.substack.com/p/software-engineering-lessons-from"
                text="https://amanjain.substack.com/p/software-engineering-lessons-from" />
            <LinkExternal
                href="https://www.discoursemagazine.com/culture-and-society/2020/08/18/systems-and-constraints/"
                text="https://www.discoursemagazine.com/culture-and-society/2020/08/18/systems-and-constraints/" />
            <LinkExternal href="https://shopify.engineering/how-shopify-reduced-storefront-response-times-rewrite"
                text="https://shopify.engineering/how-shopify-reduced-storefront-response-times-rewrite" />
            <LinkExternal href="https://critter.blog/2020/08/20/plan-the-sprint-not-the-project/"
                text="https://critter.blog/2020/08/20/plan-the-sprint-not-the-project/" />
            <LinkExternal
                href="https://www.deprocrastination.co/blog/how-to-stop-procrastinating-by-using-the-fogg-behavior-model"
                text="https://www.deprocrastination.co/blog/how-to-stop-procrastinating-by-using-the-fogg-behavior-model" />
            <LinkExternal
                href="https://thoughtcatalog.com/shahida-arabi/2017/11/5-powerful-ways-to-rise-again-after-emotional-abuse/"
                text="https://thoughtcatalog.com/shahida-arabi/2017/11/5-powerful-ways-to-rise-again-after-emotional-abuse/" />
            <LinkExternal
                href="https://thoughtcatalog.com/shahida-arabi/2018/03/this-is-how-you-break-up-with-a-narcissist-for-good/"
                text="https://thoughtcatalog.com/shahida-arabi/2018/03/this-is-how-you-break-up-with-a-narcissist-for-good/" />
            <LinkExternal href="https://www.livescience.com/22109-cycles-violence-2020.html"
                text="https://www.livescience.com/22109-cycles-violence-2020.html" />
            <LinkExternal href="https://www.zmescience.com/science/geology/roman-concrete-seawater-043432/"
                text="https://www.zmescience.com/science/geology/roman-concrete-seawater-043432/" />
            <LinkExternal
                href="https://www.vox.com/the-goods/2019/7/8/18772467/nootropics-silicon-valley-brain-fitness-goop-smart-drugs"
                text="https://www.vox.com/the-goods/2019/7/8/18772467/nootropics-silicon-valley-brain-fitness-goop-smart-drugs" />
            <LinkExternal href="https://thewalrus.ca/why-being-bored-is-good/"
                text="https://thewalrus.ca/why-being-bored-is-good/" />
            <LinkExternal
                href="https://github.com/dotnet/command-line-api/blob/main/docs/Your-first-app-with-System-CommandLine.md"
                text="https://github.com/dotnet/command-line-api/blob/main/docs/Your-first-app-with-System-CommandLine.md" />
            <LinkExternal
                href="https://circleci.com/blog/the-little-legacy-code-that-could-a-fable-of-software-ownership/"
                text="https://circleci.com/blog/the-little-legacy-code-that-could-a-fable-of-software-ownership/" />
            <LinkExternal href="https://freshchalk.com/blog/150k-small-business-website-teardown-2019"
                text="https://freshchalk.com/blog/150k-small-business-website-teardown-2019" />
            <LinkExternal
                href="https://techbeacon.com/app-dev-testing/forget-monoliths-vs-microservices-cognitive-load-what-matters"
                text="https://techbeacon.com/app-dev-testing/forget-monoliths-vs-microservices-cognitive-load-what-matters" />
            <LinkExternal href="https://f-of-e.org/" text="https://f-of-e.org/" />
            <LinkExternal
                href="https://blog.pragmaticengineer.com/what-silicon-valley-gets-right-on-software-engineers/"
                text="https://blog.pragmaticengineer.com/what-silicon-valley-gets-right-on-software-engineers/" />
            <LinkExternal href="http://algorithmsbook.com/" text="http://algorithmsbook.com/" />
            <LinkExternal href="https://www.nytimes.com/2019/06/15/us/politics/trump-cyber-russia-grid.html"
                text="https://www.nytimes.com/2019/06/15/us/politics/trump-cyber-russia-grid.html" />
            <LinkExternal href="https://www.cbinsights.com/research/future-of-information-warfare/"
                text="https://www.cbinsights.com/research/future-of-information-warfare/" />
            <LinkExternal
                href="https://kotaku.com/cd-projekt-red-boss-again-promises-that-cyberpunk-devs-1835518344/amp"
                text="https://kotaku.com/cd-projekt-red-boss-again-promises-that-cyberpunk-devs-1835518344/amp" />
            <LinkExternal href="https://en.m.wikipedia.org/wiki/Many-valued_logic"
                text="https://en.m.wikipedia.org/wiki/Many-valued_logic" />
            <LinkExternal href="https://staltz.com/software-below-the-poverty-line.html"
                text="https://staltz.com/software-below-the-poverty-line.html" />
            <LinkExternal href="https://www.kitchensoap.com/2012/10/25/on-being-a-senior-engineer/"
                text="https://www.kitchensoap.com/2012/10/25/on-being-a-senior-engineer/" />
            <LinkExternal href="https://monicalent.com/blog/2019/06/03/absolute-truths-unlearned-as-junior-developer/"
                text="https://monicalent.com/blog/2019/06/03/absolute-truths-unlearned-as-junior-developer/" />
            <LinkExternal href="https://www.sqreen.com/checklists/saas-cto-security-checklist"
                text="https://www.sqreen.com/checklists/saas-cto-security-checklist" />
            <LinkExternal href="https://github.com/jsuites/jsuites" text="https://github.com/jsuites/jsuites" />
            <LinkExternal href="https://www.bootstrapworld.org/" text="https://www.bootstrapworld.org/" />
            <LinkExternal href="https://martinfowler.com/articles/is-quality-worth-cost.html"
                text="https://martinfowler.com/articles/is-quality-worth-cost.html" />
            <LinkExternal href="https://khalilstemmler.com/articles/solid-principles/solid-typescript/"
                text="https://khalilstemmler.com/articles/solid-principles/solid-typescript/" />
            <LinkExternal href="http://standards.rest/" text="http://standards.rest/" />
            <LinkExternal href="https://blog.doist.com/deep-work/" text="https://blog.doist.com/deep-work/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=19898645"
                text="https://news.ycombinator.com/item?id=19898645" />
            <LinkExternal href="https://github.com/lorin/resilience-engineering/blob/master/intro.md"
                text="https://github.com/lorin/resilience-engineering/blob/master/intro.md" />
            <LinkExternal
                href="https://www.freecodecamp.org/news/fundamental-design-principles-for-non-designers-ad34c30caa7/"
                text="https://www.freecodecamp.org/news/fundamental-design-principles-for-non-designers-ad34c30caa7/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=19900955"
                text="https://news.ycombinator.com/item?id=19900955" />
            <LinkExternal href="https://news.ycombinator.com/item?id=19847882"
                text="https://news.ycombinator.com/item?id=19847882" />
            <LinkExternal href="https://9to5mac.com/2019/05/07/apple-sap-partnership-expansion/amp/"
                text="https://9to5mac.com/2019/05/07/apple-sap-partnership-expansion/amp/" />
            <LinkExternal href="https://www.inkandswitch.com/muse-studio-for-ideas.html"
                text="https://www.inkandswitch.com/muse-studio-for-ideas.html" />
            <LinkExternal href="https://news.ycombinator.com/item?id=19681270"
                text="https://news.ycombinator.com/item?id=19681270" />
            <LinkExternal href="https://www.berkeleyopenarms.org/" text="https://www.berkeleyopenarms.org/" />
            <LinkExternal href="https://github.com/arkenfox/user.js" text="https://github.com/arkenfox/user.js" />
            <LinkExternal href="https://news.ycombinator.com/from?site=stephaniehurlburt.com"
                text="https://news.ycombinator.com/from?site=stephaniehurlburt.com" />
            <LinkExternal href="https://hn.algolia.com/?q=jetson+nano" text="https://hn.algolia.com/?q=jetson+nano" />
            <LinkExternal href="https://www.ieee-ras.org/technical-committees"
                text="https://www.ieee-ras.org/technical-committees" />
            <LinkExternal
                href="https://om.co/2019/04/08/microsoft-cto-kevin-scott-on-the-future-of-software-engineering-and-the-better-world-ahead/"
                text="https://om.co/2019/04/08/microsoft-cto-kevin-scott-on-the-future-of-software-engineering-and-the-better-world-ahead/" />
            <LinkExternal href="http://blevesearch.com/" text="http://blevesearch.com/" />
            <LinkExternal
                href="https://www.healthboards.com/boards/eye-vision/637792-flashing-only-when-entering-dark-room.html"
                text="https://www.healthboards.com/boards/eye-vision/637792-flashing-only-when-entering-dark-room.html" />
            <LinkExternal
                href="https://www.newyorker.com/magazine/2019/04/08/the-challenge-of-going-off-psychiatric-drugs"
                text="https://www.newyorker.com/magazine/2019/04/08/the-challenge-of-going-off-psychiatric-drugs" />
            <LinkExternal
                href="https://duncanr.medium.com/deadlines-are-killing-us-and-almost-everything-else-i-know-about-leadership-7032a5fb12ac"
                text="https://duncanr.medium.com/deadlines-are-killing-us-and-almost-everything-else-i-know-about-leadership-7032a5fb12ac" />
            <LinkExternal href="https://ricomariani.medium.com/c-88-the-original-c-66a1b5de47d7"
                text="https://ricomariani.medium.com/c-88-the-original-c-66a1b5de47d7" />
            <LinkExternal href="https://codeblog.jonskeet.uk/2019/03/27/storing-utc-is-not-a-silver-bullet/"
                text="https://codeblog.jonskeet.uk/2019/03/27/storing-utc-is-not-a-silver-bullet/" />
            <LinkExternal href="https://tonsky.me/blog/hiring/" text="https://tonsky.me/blog/hiring/" />
            <LinkExternal
                href="https://serverfault.com/questions/22414/how-can-i-run-debian-stable-but-install-some-packages-from-testing"
                text="https://serverfault.com/questions/22414/how-can-i-run-debian-stable-but-install-some-packages-from-testing" />
            <LinkExternal href="https://dougie.io/linux/hidpi-retina-i3wm/"
                text="https://dougie.io/linux/hidpi-retina-i3wm/" />
            <LinkExternal
                href="http://0pointer.net/blog/unlocking-luks2-volumes-with-tpm2-fido2-pkcs11-security-hardware-on-systemd-248.html"
                text="http://0pointer.net/blog/unlocking-luks2-volumes-with-tpm2-fido2-pkcs11-security-hardware-on-systemd-248.html" />
            <LinkExternal href="https://www.focalityapp.com/en/resources/time-management-strategies/"
                text="https://www.focalityapp.com/en/resources/time-management-strategies/" />
            <LinkExternal href="https://support.apple.com/airpods-pro-service-program-sound-issues"
                text="https://support.apple.com/airpods-pro-service-program-sound-issues" />
            <LinkExternal href="https://tooslowexception.com/net-gc-internals-mini-series/"
                text="https://tooslowexception.com/net-gc-internals-mini-series/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25874374"
                text="https://news.ycombinator.com/item?id=25874374" />
            <LinkExternal href="https://thereader.mitpress.mit.edu/how-cognitive-bias-can-explain-post-truth/"
                text="https://thereader.mitpress.mit.edu/how-cognitive-bias-can-explain-post-truth/" />
            <LinkExternal href="https://gitnews.tech/" text="https://gitnews.tech/" />
            <LinkExternal href="https://boris-marinov.github.io/category-theory-illustrated/03_monoid/"
                text="https://boris-marinov.github.io/category-theory-illustrated/03_monoid/" />
            <LinkExternal href="https://boris-marinov.github.io/category-theory-illustrated/03_monoid/"
                text="https://boris-marinov.github.io/category-theory-illustrated/03_monoid/" />
            <LinkExternal
                href="https://medium.com/better-programming/i-almost-got-fired-for-choosing-react-in-our-enterprise-app-846ea840841c"
                text="https://medium.com/better-programming/i-almost-got-fired-for-choosing-react-in-our-enterprise-app-846ea840841c" />
            <LinkExternal
                href="https://www.anl.gov/article/pivotal-discovery-in-quantum-and-classical-information-processing"
                text="https://www.anl.gov/article/pivotal-discovery-in-quantum-and-classical-information-processing" />
            <LinkExternal href="https://iconduck.com/" text="https://iconduck.com/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25877052"
                text="https://news.ycombinator.com/item?id=25877052" />
            <LinkExternal
                href="https://www.quantamagazine.org/foundations-built-for-a-general-theory-of-neural-networks-20190131/"
                text="https://www.quantamagazine.org/foundations-built-for-a-general-theory-of-neural-networks-20190131/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25885699"
                text="https://news.ycombinator.com/item?id=25885699" />
            <LinkExternal href="https://symas.com/cache-management-lessons-learned/"
                text="https://symas.com/cache-management-lessons-learned/" />
            <LinkExternal href="https://deno.land/" text="https://deno.land/" />
            <LinkExternal href="https://visualstudiomagazine.com/articles/2021/01/21/win32-apis.aspx?m=1"
                text="https://visualstudiomagazine.com/articles/2021/01/21/win32-apis.aspx?m=1" />
            <LinkExternal href="https://www.smashingmagazine.com/2021/01/smashingmag-performance-case-study/"
                text="https://www.smashingmagazine.com/2021/01/smashingmag-performance-case-study/" />
            <LinkExternal href="https://www.programmingfonts.org/" text="https://www.programmingfonts.org/" />
            <LinkExternal href="https://dygk.medium.com/designing-org-73e7b27a5bac"
                text="https://dygk.medium.com/designing-org-73e7b27a5bac" />
            <LinkExternal href="https://github.com/revolist/revogrid" text="https://github.com/revolist/revogrid" />
            <LinkExternal
                href="https://www.theguardian.com/world/2021/jan/24/as-birth-rates-fall-animals-prowl-in-our-abandoned-ghost-villages"
                text="https://www.theguardian.com/world/2021/jan/24/as-birth-rates-fall-animals-prowl-in-our-abandoned-ghost-villages" />
            <LinkExternal href="https://drdru.github.io/" text="https://drdru.github.io/" />
            <LinkExternal
                href="http://www.midnighteye.com/features/post-human-nightmares-the-world-of-japanese-cyberpunk-cinema/"
                text="http://www.midnighteye.com/features/post-human-nightmares-the-world-of-japanese-cyberpunk-cinema/" />
            <LinkExternal
                href="https://www.axios.com/google-privacy-friendly-substitute-cookies-test-05c2c28e-77f1-4921-9a99-1ef0c009b064.html"
                text="https://www.axios.com/google-privacy-friendly-substitute-cookies-test-05c2c28e-77f1-4921-9a99-1ef0c009b064.html" />
            <LinkExternal href="https://dygk.medium.com/designing-org-73e7b27a5bac"
                text="https://dygk.medium.com/designing-org-73e7b27a5bac" />
            <LinkExternal href="https://github.com/basnijholt/home-assistant-config"
                text="https://github.com/basnijholt/home-assistant-config" />
            <LinkExternal
                href="https://insights.sei.cmu.edu/sei_blog/2019/08/why-software-architects-must-be-involved-in-the-earliest-systems-engineering-activities.html"
                text="https://insights.sei.cmu.edu/sei_blog/2019/08/why-software-architects-must-be-involved-in-the-earliest-systems-engineering-activities.html" />
            <LinkExternal href="https://herbertograca.com/2019/08/12/documenting-software-architecture/"
                text="https://herbertograca.com/2019/08/12/documenting-software-architecture/" />
            <LinkExternal href="https://i3wm.org/docs/userguide.html" text="https://i3wm.org/docs/userguide.html" />
            <LinkExternal href="https://i3wm.org/docs/refcard.html" text="https://i3wm.org/docs/refcard.html" />
            <LinkExternal href="https://www.reddit.com/r/i3wm/" text="https://www.reddit.com/r/i3wm/" />
            <LinkExternal href="https://www.reddit.com/r/unixporn/" text="https://www.reddit.com/r/unixporn/" />
            <LinkExternal href="https://blog.google/threat-analysis-group/new-campaign-targeting-security-researchers/"
                text="https://blog.google/threat-analysis-group/new-campaign-targeting-security-researchers/" />
            <LinkExternal
                href="https://www.entsoe.eu/news/2021/01/26/system-separation-in-the-continental-europe-synchronous-area-on-8-january-2021-2nd-update/"
                text="https://www.entsoe.eu/news/2021/01/26/system-separation-in-the-continental-europe-synchronous-area-on-8-january-2021-2nd-update/" />
            <LinkExternal href="https://blog.mozilla.org/security/2021/01/26/supercookie-protections/"
                text="https://blog.mozilla.org/security/2021/01/26/supercookie-protections/" />
            <LinkExternal href="https://nouveau.freedesktop.org/Optimus.html"
                text="https://nouveau.freedesktop.org/Optimus.html" />
            <LinkExternal href="https://www.joshwcomeau.com/css/styled-components/"
                text="https://www.joshwcomeau.com/css/styled-components/" />
            <LinkExternal
                href="https://m-cacm.acm.org/magazines/2021/2/250085-a-review-of-the-semantic-web-field/fulltext"
                text="https://m-cacm.acm.org/magazines/2021/2/250085-a-review-of-the-semantic-web-field/fulltext" />
            <LinkExternal href="https://blog.regehr.org/archives/861" text="https://blog.regehr.org/archives/861" />
            <LinkExternal
                href="https://devblogs.microsoft.com/premier-developer/avoiding-struct-and-readonly-reference-performance-pitfalls-with-errorprone-net/"
                text="https://devblogs.microsoft.com/premier-developer/avoiding-struct-and-readonly-reference-performance-pitfalls-with-errorprone-net/" />
            <LinkExternal
                href="https://devblogs.microsoft.com/premier-developer/performance-implications-of-default-struct-equality-in-c/"
                text="https://devblogs.microsoft.com/premier-developer/performance-implications-of-default-struct-equality-in-c/" />
            <LinkExternal
                href="https://www.theverge.com/2020/12/16/22178068/facebook-apple-newspaper-ads-ios-privacy-changes"
                text="https://www.theverge.com/2020/12/16/22178068/facebook-apple-newspaper-ads-ios-privacy-changes" />
            <LinkExternal
                href="https://www.jsonline.com/story/news/politics/2021/01/28/wisconsin-republicans-poised-end-gov-tony-evers-mask-mandate/4279696001/"
                text="https://www.jsonline.com/story/news/politics/2021/01/28/wisconsin-republicans-poised-end-gov-tony-evers-mask-mandate/4279696001/" />
            <LinkExternal href="https://tylerclementi.org/catholicbishopsstatement/"
                text="https://tylerclementi.org/catholicbishopsstatement/" />
            <LinkExternal
                href="https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls%3A-the-autocomplete-attribute"
                text="https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls%3A-the-autocomplete-attribute" />
            <LinkExternal
                href="https://docs.microsoft.com/en-us/sql/database-engine/configure-windows/server-memory-server-configuration-options?view=sql-server-ver15"
                text="https://docs.microsoft.com/en-us/sql/database-engine/configure-windows/server-memory-server-configuration-options?view=sql-server-ver15" />
            <LinkExternal href="https://www.c-span.org/video/?508409-1/senate-session"
                text="https://www.c-span.org/video/?508409-1/senate-session" />
            <LinkExternal href="https://blog.seekwell.io/gpt3" text="https://blog.seekwell.io/gpt3" />
            <LinkExternal href="https://astralcodexten.substack.com/p/ontology-of-psychiatric-conditions"
                text="https://astralcodexten.substack.com/p/ontology-of-psychiatric-conditions" />
            <LinkExternal href="https://nn.labml.ai/" text="https://nn.labml.ai/" />
            <LinkExternal href="https://nintil.com/programming" text="https://nintil.com/programming" />
            <LinkExternal href="https://www.c-span.org/about/faq/" text="https://www.c-span.org/about/faq/" />
            <LinkExternal href="https://dotnet.microsoft.com/apps/machinelearning-ai/ml-dotnet"
                text="https://dotnet.microsoft.com/apps/machinelearning-ai/ml-dotnet" />
            <LinkExternal
                href="https://www.theverge.com/2021/1/30/22257950/go-read-this-software-coronavirus-vaccine-cdc"
                text="https://www.theverge.com/2021/1/30/22257950/go-read-this-software-coronavirus-vaccine-cdc" />
            <LinkExternal href="https://www.pnas.org/content/118/6/e2008389118"
                text="https://www.pnas.org/content/118/6/e2008389118" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25975110"
                text="https://news.ycombinator.com/item?id=25975110" />
            <LinkExternal
                href="https://www.sciencefocus.com/the-human-body/afternoon-naps-linked-to-improved-cognitive-function/"
                text="https://www.sciencefocus.com/the-human-body/afternoon-naps-linked-to-improved-cognitive-function/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=25975110"
                text="https://news.ycombinator.com/item?id=25975110" />
            <LinkExternal href="https://nplusonemag.com/online-only/online-only/salt-fat-acid-defeat/"
                text="https://nplusonemag.com/online-only/online-only/salt-fat-acid-defeat/" />
            <LinkExternal
                href="https://ottawacitizen.com/news/local-news/the-downside-of-clean-scientists-fear-pandemics-hyper-hygiene-could-have-long-term-health-impacts"
                text="https://ottawacitizen.com/news/local-news/the-downside-of-clean-scientists-fear-pandemics-hyper-hygiene-could-have-long-term-health-impacts" />
            <LinkExternal
                href="https://ruben.verborgh.org/blog/2020/12/07/a-data-ecosystem-fosters-sustainable-innovation/"
                text="https://ruben.verborgh.org/blog/2020/12/07/a-data-ecosystem-fosters-sustainable-innovation/" />
            <LinkExternal href="https://onurgumus.github.io/2021/01/31/What-the-F.html"
                text="https://onurgumus.github.io/2021/01/31/What-the-F.html" />
            <LinkExternal href="https://devnet.kentico.com/articles/multisite-environment"
                text="https://devnet.kentico.com/articles/multisite-environment" />
            <LinkExternal
                href="https://docs.xperience.io/k12sp/installation/manually-configuring-kentico-applications-in-iis"
                text="https://docs.xperience.io/k12sp/installation/manually-configuring-kentico-applications-in-iis" />
            <LinkExternal href="https://devnet.kentico.com/questions/multi-domain-kentico-mvc-error-in-admin"
                text="https://devnet.kentico.com/questions/multi-domain-kentico-mvc-error-in-admin" />
            <LinkExternal
                href="https://docs.xperience.io/k12sp/installation/manually-configuring-kentico-applications-in-iis"
                text="https://docs.xperience.io/k12sp/installation/manually-configuring-kentico-applications-in-iis" />
            <LinkExternal href="https://www.troyhunt.com/how-to-build-and-how-not-to-build/"
                text="https://www.troyhunt.com/how-to-build-and-how-not-to-build/" />
            <LinkExternal href="https://www.troyhunt.com/how-to-build-and-how-not-to-build/"
                text="https://www.troyhunt.com/how-to-build-and-how-not-to-build/" />
            <LinkExternal
                href="https://docs.xperience.io/k12sp/multilingual-websites/setting-up-multilingual-mvc-projects"
                text="https://docs.xperience.io/k12sp/multilingual-websites/setting-up-multilingual-mvc-projects" />
            <LinkExternal href="https://carltheperson.com/posts/10-things-linux"
                text="https://carltheperson.com/posts/10-things-linux" />
            <LinkExternal href="https://ascendixtech.com/software-development-outsourcing-our-story/"
                text="https://ascendixtech.com/software-development-outsourcing-our-story/" />
            <LinkExternal href="https://www.danielwatts.info/post/7-behaviours-to-avoid-software-architect/"
                text="https://www.danielwatts.info/post/7-behaviours-to-avoid-software-architect/" />
            <LinkExternal href="https://github.com/jotaen/klog" text="https://github.com/jotaen/klog" />
            <LinkExternal href="https://blog.jfedor.org/2021/01/bluetooth-trackball-mark-ii.html?m=1"
                text="https://blog.jfedor.org/2021/01/bluetooth-trackball-mark-ii.html?m=1" />
            <LinkExternal href="https://www.quantamagazine.org/the-octonion-math-that-could-underpin-physics-20180720/"
                text="https://www.quantamagazine.org/the-octonion-math-that-could-underpin-physics-20180720/" />
            <LinkExternal href="https://aeon.co/essays/what-complexity-science-says-about-what-makes-a-winning-team"
                text="https://aeon.co/essays/what-complexity-science-says-about-what-makes-a-winning-team" />
            <LinkExternal href="https://aeon.co/essays/what-complexity-science-says-about-what-makes-a-winning-team"
                text="https://aeon.co/essays/what-complexity-science-says-about-what-makes-a-winning-team" />
            <LinkExternal href="https://notes.eatonphil.com/books-developers-should-read.html"
                text="https://notes.eatonphil.com/books-developers-should-read.html" />
            <LinkExternal href="https://www.okayhq.com/blog/engineering-productivity-can-be-measured"
                text="https://www.okayhq.com/blog/engineering-productivity-can-be-measured" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26014455"
                text="https://news.ycombinator.com/item?id=26014455" />
            <LinkExternal href="https://gabrielgambetta.com/computer-graphics-from-scratch/"
                text="https://gabrielgambetta.com/computer-graphics-from-scratch/" />
            <LinkExternal href="https://github.com/tildearrow/input-modifier"
                text="https://github.com/tildearrow/input-modifier" />
            <LinkExternal href="https://ntietz.com/tech-blog/drawbacks-of-developing-in-containers/"
                text="https://ntietz.com/tech-blog/drawbacks-of-developing-in-containers/" />
            <LinkExternal href="http://arbtt.nomeata.de/#what" text="http://arbtt.nomeata.de/#what" />
            <LinkExternal href="https://www.ytt-tech.com/v/Installing-an-Outdoor-Electrical-Outlet"
                text="https://www.ytt-tech.com/v/Installing-an-Outdoor-Electrical-Outlet" />
            <LinkExternal href="https://learnreact.design/posts/what-is-react"
                text="https://learnreact.design/posts/what-is-react" />
            <LinkExternal href="https://havenweb.org/" text="https://havenweb.org/" />
            <LinkExternal href="http://www.geofcrowl.com/blog/articles/2020/2/17/collection-higs/"
                text="http://www.geofcrowl.com/blog/articles/2020/2/17/collection-higs/" />
            <LinkExternal href="https://github.com/filipsPL/cat-localizer"
                text="https://github.com/filipsPL/cat-localizer" />
            <LinkExternal
                href="https://arstechnica.com/science/2021/02/signs-that-sars-cov-2-is-evolving-to-avoid-immune-responses/?amp=1"
                text="https://arstechnica.com/science/2021/02/signs-that-sars-cov-2-is-evolving-to-avoid-immune-responses/?amp=1" />
            <LinkExternal
                href="https://arstechnica.com/science/2021/02/signs-that-sars-cov-2-is-evolving-to-avoid-immune-responses/"
                text="https://arstechnica.com/science/2021/02/signs-that-sars-cov-2-is-evolving-to-avoid-immune-responses/" />
            <LinkExternal
                href="https://www.4freerussia.org/wp-content/uploads/sites/3/2019/11/Some-Aspects-of-Training-the-Operative.pdf"
                text="https://www.4freerussia.org/wp-content/uploads/sites/3/2019/11/Some-Aspects-of-Training-the-Operative.pdf" />
            <LinkExternal
                href="https://denverite.com/2021/02/02/in-the-first-six-months-of-health-care-professionals-replacing-police-officers-no-one-they-encountered-was-arrested/"
                text="https://denverite.com/2021/02/02/in-the-first-six-months-of-health-care-professionals-replacing-police-officers-no-one-they-encountered-was-arrested/" />
            <LinkExternal
                href="https://capitalandgrowth.org/answers/Article/3151034/The-One-Word-Change-that-Turned-the-Tide-and-Led-to-Brexit"
                text="https://capitalandgrowth.org/answers/Article/3151034/The-One-Word-Change-that-Turned-the-Tide-and-Led-to-Brexit" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26047356"
                text="https://news.ycombinator.com/item?id=26047356" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26045380"
                text="https://news.ycombinator.com/item?id=26045380" />
            <LinkExternal href="https://pawelurbanek.com/cloudflare-free-plan"
                text="https://pawelurbanek.com/cloudflare-free-plan" />
            <LinkExternal href="https://shithub.us/" text="https://shithub.us/" />
            <LinkExternal href="https://shithub.us/" text="https://shithub.us/" />
            <LinkExternal href="https://github.com/neo-mc/neo-mc" text="https://github.com/neo-mc/neo-mc" />
            <LinkExternal href="https://realclearconservatism.com/2021/02/07/forgiveness-the-essential-virtue/"
                text="https://realclearconservatism.com/2021/02/07/forgiveness-the-essential-virtue/" />
            <LinkExternal href="https://languagelog.ldc.upenn.edu/nll/?p=1024"
                text="https://languagelog.ldc.upenn.edu/nll/?p=1024" />
            <LinkExternal href="https://complexityexplained.github.io/" text="https://complexityexplained.github.io/" />
            <LinkExternal href="https://phys.org/news/2021-02-quantum-entire-radio-frequency-spectrum.html"
                text="https://phys.org/news/2021-02-quantum-entire-radio-frequency-spectrum.html" />
            <LinkExternal href="https://www.nytimes.com/2021/02/03/realestate/luxury-high-rise-432-park.html"
                text="https://www.nytimes.com/2021/02/03/realestate/luxury-high-rise-432-park.html" />
            <LinkExternal href="https://pages.cs.wisc.edu/~remzi/OSTEP/"
                text="https://pages.cs.wisc.edu/~remzi/OSTEP/" />
            <LinkExternal href="http://www.threatmodelingmanifesto.org/"
                text="http://www.threatmodelingmanifesto.org/" />
            <LinkExternal
                href="https://tedunderwood.com/2021/02/02/why-sf-hasnt-prepared-us-to-imagine-machine-learning/"
                text="https://tedunderwood.com/2021/02/02/why-sf-hasnt-prepared-us-to-imagine-machine-learning/" />
            <LinkExternal href="https://tools.ietf.org/html/rfc1178" text="https://tools.ietf.org/html/rfc1178" />
            <LinkExternal href="https://robotmoon.com/ssh-tunnels/" text="https://robotmoon.com/ssh-tunnels/" />
            <LinkExternal href="http://m.nautil.us/blog/how-universes-might-bubble-up-and-collide"
                text="http://m.nautil.us/blog/how-universes-might-bubble-up-and-collide" />
            <LinkExternal
                href="https://www.cbc.ca/radio/quirks/scientists-develop-transparent-wood-that-is-stronger-and-lighter-than-glass-1.5902739"
                text="https://www.cbc.ca/radio/quirks/scientists-develop-transparent-wood-that-is-stronger-and-lighter-than-glass-1.5902739" />
            <LinkExternal
                href="http://themediocreprogrammer.com/what-is-the-mediocre-programmer.html#what-is-the-mediocre-programmer"
                text="http://themediocreprogrammer.com/what-is-the-mediocre-programmer.html#what-is-the-mediocre-programmer" />
            <LinkExternal href="https://programmingforcats.com/" text="https://programmingforcats.com/" />
            <LinkExternal href="https://matklad.github.io//2021/02/06/ARCHITECTURE.md.html"
                text="https://matklad.github.io//2021/02/06/ARCHITECTURE.md.html" />
            <LinkExternal href="https://bartoszsypytkowski.com/writing-high-performance-f-code/"
                text="https://bartoszsypytkowski.com/writing-high-performance-f-code/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26050007"
                text="https://news.ycombinator.com/item?id=26050007" />
            <LinkExternal
                href="https://www.wsj.com/amp/articles/coronavirus-most-likely-spilled-over-to-humans-through-intermediate-animal-says-who-11612868217"
                text="https://www.wsj.com/amp/articles/coronavirus-most-likely-spilled-over-to-humans-through-intermediate-animal-says-who-11612868217" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26076178"
                text="https://news.ycombinator.com/item?id=26076178" />
            <LinkExternal
                href="https://blog.lidskialf.net/2021/02/08/turning-an-old-kindle-into-a-eink-development-platform/"
                text="https://blog.lidskialf.net/2021/02/08/turning-an-old-kindle-into-a-eink-development-platform/" />
            <LinkExternal href="https://github.com/smucclaw/dsl" text="https://github.com/smucclaw/dsl" />
            <LinkExternal href="https://www.nytimes.com/interactive/2021/02/09/us/trump-voter-fraud-election.html"
                text="https://www.nytimes.com/interactive/2021/02/09/us/trump-voter-fraud-election.html" />
            <LinkExternal href="https://www.nature.com/articles/d41586-019-02585-6"
                text="https://www.nature.com/articles/d41586-019-02585-6" />
            <LinkExternal href="https://cs193p.sites.stanford.edu/" text="https://cs193p.sites.stanford.edu/" />
            <LinkExternal
                href="https://apnews.com/article/election-2020-joe-biden-donald-trump-capitol-siege-media-e79eb5164613d6718e9f4502eb471f27"
                text="https://apnews.com/article/election-2020-joe-biden-donald-trump-capitol-siege-media-e79eb5164613d6718e9f4502eb471f27" />
            <LinkExternal href="https://albertazout.substack.com/p/gradient-ascent-10"
                text="https://albertazout.substack.com/p/gradient-ascent-10" />
            <LinkExternal href="https://www.scrapingbee.com/blog/web-scraping-101-with-python/"
                text="https://www.scrapingbee.com/blog/web-scraping-101-with-python/" />
            <LinkExternal href="https://engineering.q42.nl/webassembly/"
                text="https://engineering.q42.nl/webassembly/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26091100"
                text="https://news.ycombinator.com/item?id=26091100" />
            <LinkExternal
                href="https://engineering.atspotify.com/2021/02/11/how-spotify-optimized-the-largest-dataflow-job-ever-for-wrapped-2020/"
                text="https://engineering.atspotify.com/2021/02/11/how-spotify-optimized-the-largest-dataflow-job-ever-for-wrapped-2020/" />
            <LinkExternal href="https://github.com/Droogans/unmaintainable-code"
                text="https://github.com/Droogans/unmaintainable-code" />
            <LinkExternal href="https://www.youtube.com/watch?v=hVqrW-fPOQ0"
                text="https://www.youtube.com/watch?v=hVqrW-fPOQ0" />
            <LinkExternal href="https://devblogs.microsoft.com/dotnet/staying-safe-with-dotnet-containers/"
                text="https://devblogs.microsoft.com/dotnet/staying-safe-with-dotnet-containers/" />
            <LinkExternal
                href="https://www.economist.com/science-and-technology/2021/02/10/microbial-ecosystems-in-the-mouth-and-gut-are-linked-to-many-ills"
                text="https://www.economist.com/science-and-technology/2021/02/10/microbial-ecosystems-in-the-mouth-and-gut-are-linked-to-many-ills" />
            <LinkExternal
                href="https://www.reddit.com/r/homeassistant/comments/9b05kb/air_quality_monitoring/?utm_source=share&utm_medium=ios_app&utm_name=iossmf"
                text="https://www.reddit.com/r/homeassistant/comments/9b05kb/air_quality_monitoring/?utm_source=share&utm_medium=ios_app&utm_name=iossmf" />
            <LinkExternal
                href="https://medium.com/better-humans/how-to-use-psychology-to-solve-the-procrastination-puzzle-6e6a56cdd535"
                text="https://medium.com/better-humans/how-to-use-psychology-to-solve-the-procrastination-puzzle-6e6a56cdd535" />
            <LinkExternal
                href="https://arstechnica.com/information-technology/2021/02/microsoft-is-seeing-a-big-spike-in-web-shell-use/"
                text="https://arstechnica.com/information-technology/2021/02/microsoft-is-seeing-a-big-spike-in-web-shell-use/" />
            <LinkExternal href="https://applied-compositional-thinking.engineering/lectures/"
                text="https://applied-compositional-thinking.engineering/lectures/" />
            <LinkExternal href="https://numinous.productions/ttft/" text="https://numinous.productions/ttft/" />
            <LinkExternal href="https://engineering.q42.nl/webassembly/"
                text="https://engineering.q42.nl/webassembly/" />
            <LinkExternal href="https://en.m.wikipedia.org/wiki/Design_pattern"
                text="https://en.m.wikipedia.org/wiki/Design_pattern" />
            <LinkExternal href="https://www.martinfowler.com/articles/writingPatterns.html#AlexandrianForm"
                text="https://www.martinfowler.com/articles/writingPatterns.html#AlexandrianForm" />
            <LinkExternal href="https://chrisgagne.com/1255/mary-poppendiecks-the-tyranny-of-the-plan/"
                text="https://chrisgagne.com/1255/mary-poppendiecks-the-tyranny-of-the-plan/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26041057"
                text="https://news.ycombinator.com/item?id=26041057" />
            <LinkExternal
                href="https://thriveglobal.in/stories/want-to-reduce-brain-fog-and-improve-clear-thinking-give-up-these-things-immediately/"
                text="https://thriveglobal.in/stories/want-to-reduce-brain-fog-and-improve-clear-thinking-give-up-these-things-immediately/" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26123394"
                text="https://news.ycombinator.com/item?id=26123394" />
            <LinkExternal href="https://www.joshwcomeau.com/react/modern-spacer-gif/"
                text="https://www.joshwcomeau.com/react/modern-spacer-gif/" />
            <LinkExternal href="https://en.m.wikipedia.org/wiki/Data,_context_and_interaction"
                text="https://en.m.wikipedia.org/wiki/Data,_context_and_interaction" />
            <LinkExternal href="https://github.com/programmersommer/DCISample"
                text="https://github.com/programmersommer/DCISample" />
            <LinkExternal href="https://martinfowler.com/articles/branching-patterns.html"
                text="https://martinfowler.com/articles/branching-patterns.html" />
            <LinkExternal
                href="https://martinfowler.com/articles/branching-patterns.html#FinalThoughtsAndRecommendations"
                text="https://martinfowler.com/articles/branching-patterns.html#FinalThoughtsAndRecommendations" />
            <LinkExternal href="https://www.ign.com/wikis/cyberpunk-2077"
                text="https://www.ign.com/wikis/cyberpunk-2077" />
            <LinkExternal href="https://apple.news/AnfagvGHuRfG92LVyDeAUyg"
                text="https://apple.news/AnfagvGHuRfG92LVyDeAUyg" />
            <LinkExternal href="https://gizmodo.com/how-do-animals-perceive-time-1846206287"
                text="https://gizmodo.com/how-do-animals-perceive-time-1846206287" />
            <LinkExternal href="https://en.wikipedia.org/wiki/Skewes%27s_number"
                text="https://en.wikipedia.org/wiki/Skewes%27s_number" />
            <LinkExternal href="https://www.hillelwayne.com/uncle-bob/" text="https://www.hillelwayne.com/uncle-bob/" />
            <LinkExternal href="https://www.home-assistant.io/blog/2020/09/15/home-assistant-tags/"
                text="https://www.home-assistant.io/blog/2020/09/15/home-assistant-tags/" />
            <LinkExternal
                href="https://arstechnica.com/science/2021/02/researchers-engineer-a-neanderthal-gene-into-human-nerve-cells/"
                text="https://arstechnica.com/science/2021/02/researchers-engineer-a-neanderthal-gene-into-human-nerve-cells/" />
            <LinkExternal href="https://esphome.io/index.html#" text="https://esphome.io/index.html#" />
            <LinkExternal href="https://www.displaymate.com/iPhone_12Pro_ShootOut_1P.htm"
                text="https://www.displaymate.com/iPhone_12Pro_ShootOut_1P.htm" />
            <LinkExternal href="https://medium.com/lookup-design/designing-a-dark-theme-for-oled-iphones-e13cdfea7ffe"
                text="https://medium.com/lookup-design/designing-a-dark-theme-for-oled-iphones-e13cdfea7ffe" />
            <LinkExternal href="https://www.archaeology.co.uk/articles/who-did-the-anglo-saxons-think-they-were.htm"
                text="https://www.archaeology.co.uk/articles/who-did-the-anglo-saxons-think-they-were.htm" />
            <LinkExternal href="https://github.com/TokyoYoshida/ExampleOfiOSLiDAR"
                text="https://github.com/TokyoYoshida/ExampleOfiOSLiDAR" />
            <LinkExternal href="https://codingsans.com/blog/leadership-soft-skills"
                text="https://codingsans.com/blog/leadership-soft-skills" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26164879"
                text="https://news.ycombinator.com/item?id=26164879" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26177355"
                text="https://news.ycombinator.com/item?id=26177355" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26177355"
                text="https://news.ycombinator.com/item?id=26177355" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26175894"
                text="https://news.ycombinator.com/item?id=26175894" />
            <LinkExternal href="https://www.twilio.com/blog/zsh-tricks-to-blow-your-mind"
                text="https://www.twilio.com/blog/zsh-tricks-to-blow-your-mind" />
            <LinkExternal href="https://apple.news/AOtNO7V9DR_KjZokbzxzToA"
                text="https://apple.news/AOtNO7V9DR_KjZokbzxzToA" />
            <LinkExternal href="https://aposymbiont.github.io/split-keyboards/"
                text="https://aposymbiont.github.io/split-keyboards/" />
            <LinkExternal
                href="https://arstechnica.com/gaming/2021/02/valheim-is-the-endless-viking-survival-game-we-have-craved-for-years/"
                text="https://arstechnica.com/gaming/2021/02/valheim-is-the-endless-viking-survival-game-we-have-craved-for-years/" />
            <LinkExternal
                href="https://www.staycaffeinated.com/2021/02/21/how-to-design-a-motherboard-for-your-project-part-1"
                text="https://www.staycaffeinated.com/2021/02/21/how-to-design-a-motherboard-for-your-project-part-1" />
            <LinkExternal href="http://music.ishkur.com/" text="http://music.ishkur.com/" />
            <LinkExternal href="https://css-tricks.com/mistakes-ive-made-as-an-engineering-manager/"
                text="https://css-tricks.com/mistakes-ive-made-as-an-engineering-manager/" />
            <LinkExternal
                href="https://www.theguardian.com/science/2021/feb/22/people-with-extremist-views-less-able-to-do-complex-mental-tasks-research-suggests"
                text="https://www.theguardian.com/science/2021/feb/22/people-with-extremist-views-less-able-to-do-complex-mental-tasks-research-suggests" />
            <LinkExternal
                href="https://www.bbc.co.uk/programmes/articles/1g84m0sXpnNCv84GpN2PLZG/the-game-30th-anniversary-edition"
                text="https://www.bbc.co.uk/programmes/articles/1g84m0sXpnNCv84GpN2PLZG/the-game-30th-anniversary-edition" />
            <LinkExternal
                href="https://blog.snappymob.com/designing-for-voice-user-interfaces-principles-and-best-practices"
                text="https://blog.snappymob.com/designing-for-voice-user-interfaces-principles-and-best-practices" />
            <LinkExternal
                href="https://www.bbc.com/culture/article/20210212-silent-running-the-sci-fi-that-predicted-modern-crises"
                text="https://www.bbc.com/culture/article/20210212-silent-running-the-sci-fi-that-predicted-modern-crises" />
            <LinkExternal href="https://wiki.c2.com/?ConceptualIntegrity"
                text="https://wiki.c2.com/?ConceptualIntegrity" />
            <LinkExternal href="https://wiki.c2.com/?ConceptualIntegrity"
                text="https://wiki.c2.com/?ConceptualIntegrity" />
            <LinkExternal href="https://www.dougengelbart.org/content/view/138"
                text="https://www.dougengelbart.org/content/view/138" />
            <LinkExternal
                href="https://blogs.sciencemag.org/pipeline/archives/2021/02/19/how-antidepressants-work-at-last"
                text="https://blogs.sciencemag.org/pipeline/archives/2021/02/19/how-antidepressants-work-at-last" />
            <LinkExternal href="https://apple.news/ACuDl979FTkqxPQa7JPeQpg"
                text="https://apple.news/ACuDl979FTkqxPQa7JPeQpg" />
            <LinkExternal href="https://news.ycombinator.com/item?id=26234384"
                text="https://news.ycombinator.com/item?id=26234384" />
            <LinkExternal href="http://tallys.github.io/color-theory/" text="http://tallys.github.io/color-theory/" />
            <LinkExternal href="http://archagon.net/blog/2018/03/24/data-laced-with-history/"
                text="http://archagon.net/blog/2018/03/24/data-laced-with-history/" />
            <LinkExternal href="https://www.netmeister.org/blog/software-engineering-laws.html"
                text="https://www.netmeister.org/blog/software-engineering-laws.html" />
            <LinkExternal
                href="https://offgridpermaculture.com/Finding_Land/Free_Land___Living_Off_Grid_With_No_Money.html"
                text="https://offgridpermaculture.com/Finding_Land/Free_Land___Living_Off_Grid_With_No_Money.html" />
            <LinkExternal href="https://homepage.divms.uiowa.edu/~jones/ternary/"
                text="https://homepage.divms.uiowa.edu/~jones/ternary/" />
            <LinkExternal href="https://github.com/trailofbits/graphtage"
                text="https://github.com/trailofbits/graphtage" />
            <LinkExternal href="https://alistapart.com/article/the-future-of-web-software-is-html-over-websockets/"
                text="https://alistapart.com/article/the-future-of-web-software-is-html-over-websockets/" />
            <LinkExternal href="https://free-for.dev/#/?id=code-quality"
                text="https://free-for.dev/#/?id=code-quality" />
            <LinkExternal
                href="https://betterprogramming.pub/understanding-decision-trees-in-machine-learning-86d750e0a38f"
                text="https://betterprogramming.pub/understanding-decision-trees-in-machine-learning-86d750e0a38f" />
            <LinkExternal
                href="https://mkarliner.medium.com/the-process-is-not-the-product-the-new-agile-anti-manifesto-d3e23023b466"
                text="https://mkarliner.medium.com/the-process-is-not-the-product-the-new-agile-anti-manifesto-d3e23023b466" />
            <LinkExternal
                href="https://www.quantamagazine.org/artificial-neural-nets-finally-yield-clues-to-how-brains-learn-20210218/"
                text="https://www.quantamagazine.org/artificial-neural-nets-finally-yield-clues-to-how-brains-learn-20210218/" />
        </div>
    );
}

export default Knowledge;
