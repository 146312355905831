import './Art.css';
import { useState, useEffect } from 'react';
import axios from 'axios'
import ArtImage from './ArtImage';

function ArtCollection(props) {

    const [collection, setCollection] = useState({})
    useEffect(() => {
        axios.get(`https://cms.kashifshah.net/art-collections/${props.id}`).then(response => {
            console.log({ lookupArtCollection: response.data })

            setCollection(response.data)

            return () => { }
        })

    }, [props.id])


    return (
        <div>
            <p className="ArtCollection-header">{collection.Title}</p>
            {collection.Body ? <p className="ArtCollection-content">{collection.Body}</p> : ''}
            <div className='ArtImage-container'>
                {collection?.art_images?.map(image => <ArtImage id={image.id} />)}
            </div>
        </div>
    );
}

export default ArtCollection;
