import '../index.css';

function InternalImage(props) {
    return (
        <div style={{paddingTop: props.spacing + 'px', paddingLeft: props.spacing + 'px'}}>
            <img src={props.src} alt={props.alt} style={{width: props.width + 'px'}}/>
        </div>
    );
}

export default InternalImage;
