import '../index.css';
import ParagraphWithHeader from "../common/ParagraphWithHeader";
import InternalImage from "../common/InternalImage";

function Driving() {
    let spacing = 28;
    let imageWidth = 384;
    return (
        <div className="Index-container">
            <ParagraphWithHeader header="#funwithcars" content="Welcome to my driver's corner."/>
            <ParagraphWithHeader header="2009 Honda Civic Si Coupe" content="Customized and maintained by both myself and King Motorsports."/>
            <InternalImage src="../img/car@0.5x.png" alt="2009 Honda Civic Si" spacing={spacing} width={imageWidth}/>
            <InternalImage src="../img/curve.jpg" alt="2009 Honda Civic Si" spacing={spacing} width={imageWidth}/>
            <ParagraphWithHeader header="2004 Honda VFR800 Interceptor" content="Customized by previous owner and maintained by myself."/>
            <InternalImage src="../img/motorcycle@0.5x.png" alt="2004 Honda VFR800 Interceptor" spacing={spacing} width={imageWidth}/>
            <ParagraphWithHeader header="Dagger Echo 14.0" content="My home on the water."/>
            <InternalImage src="../img/kayak@0.5x.png" alt="2006 Dagger Echo 14.0" spacing={spacing} width={imageWidth}/>
        </div>
    );
}

export default Driving;
